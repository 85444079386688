import { requests } from "./apiagent";
import axios from "axios";
import { apiConfig } from "./apiConfig";
import { sortArrayInStandardDeckOrder } from "../utils/ListUtils";
import { getSessionLoggedInUser } from "../helpers/SessionHelper";

const getAPI = async (url) => {
	let results = await requests
		.get(url)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			//console.log(err);
			return err;
		});
	return results;
};

//#region Login
export const requestAuth = async (userCredentials) => {
	let repeatRequestCount = 0;
	let result = await requests
		.post("/authenticate/web", userCredentials, {}, repeatRequestCount)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			return err;
		});
	return result;
};

export const submitAuth = async (mfaCode, accessToken) => {
	// The user in not logged in at this point, but we have  atoken from the prior requestAuth api call
	// nned JSON.stringify otherwise we geta  absd request
	let lConfig = { headers: { Authorization: "Bearer " + accessToken } };
	let result = await requests
		.post("/authenticate/web/mfa", JSON.stringify(mfaCode), lConfig)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.log(err);
			return err;
		});
	return result;
};

export const getAssociatedExporter = async (userID) =>
	await getAPI(`/exporters/users/${userID}`);

export const setTermsAndConditionsAgreedTo = async () => {
	let results = await requests
		.post(`/users/settermsandconditionsagreedto`)
		.then((res) => {
			if (res.status === 200) {
				return res.json();
			}
		})
		.catch((err) => {
			console.log(err);
		});
	return results;
};
//#endregion

//#region donwload access
export const getDownloadAccess = async (password, callback) => {
	let data = JSON.stringify(password);

	let config = {
		method: "post",
		maxBodyLength: Infinity,
		url: apiConfig.baseURL + "authenticate/web/downloadaccess",
		headers: {
			"x-api-key": "sZksEIse1vfrTLc1QkDnRTTPOAYBomvY1ub/HIYgIkM=",
			"Content-Type": "application/json",
		},
		data: data,
	};

	return axios
		.request(config)
		.then((response) => {
			// console.log(JSON.stringify(response.data));
			callback(response);
		})
		.catch((error) => {
			// console.log(error);
			callback(undefined);
		});
};
//#endregion

//#region Master Data
export const getDeparturePorts = async () => await getAPI("/ports/departure");

export const getDestinationPorts = async () =>
	await getAPI("/ports/destination");

export const getVessels = async () => await getAPI("/vessels");

export const getAAVs = async () => await getAPI("/users/aavs");

export const getAccreditedStockpersons = async () =>
	await getAPI("/users/accreditedstockpeople");

export const getSpecies = async () => await getAPI("/species");

export const getSpeciesClasses = async () => await getAPI("/species/classes");

export const getManagementPlans = async (include_inactive = false) =>
	await getAPI(
		`/managementplans?include_inactive=${
			include_inactive ? "true" : "false"
		}`
	);

export const getTagTypes = async () => await getAPI("/livestock/tagtypes");

export const getFeedMeasuredByOptions = async () =>
	await getAPI("/feed/measurementtypes");

export const getFeedQualityOptions = async () =>
	await getAPI("/feed/qualitytypes");

export const getSailingOptions = async () =>
	await getAPI("/observations/sailingconditions");

export const getVentilationIssueOptions = async () =>
	await getAPI("/observations/ventilationissuetypes");

export const getDetailedHealthConditions = async () =>
	await getAPI("/healthconditions");

export const getDetailedMortalityConditions = async () =>
	await getAPI("/mortalityconditions");
//#endregion

//#region Voluntary Data

export const getCommodityTypes = async () => await getAPI("/commodities/types");

export const getLivestockCategories = async () =>
	await getAPI("/livestock/categories");

//#endregion
//#region Consignments
export const submitNewConsignment = async (consignment) => {
	let parsePort = (p) => {
		return {
			id: Number(p.id),
			portName: p.portName,
			isActive: p.isActive,
			countryID: Number(p.countryID),
			countryName: p.countryName,
			countryIsActive: p.countryIsActive,
		};
	};

	let parseFeed = (f) => {
		return {
			id: null,
			pellets: Number(f.pellets),
			grain: Number(f.grain),
			roughage: Number(f.roughage),
		};
	};

	let parseCompetentStockpersons = (csp) => {
		if (csp.firstName.length === 0 || csp.lastName.length === 0) return;
		return {
			firstName: csp.firstName,
			lastName: csp.lastName,
		};
	};

	let parseLivestock = (ls) => {
		let lsArr = [];
		if (Number(ls.old.numHead) > 0) {
			lsArr.push({
				tagNumber: ls.tagNumber,
				tagTypeID: ls.tagTypeID,
				numberOfStock: Number(ls.old.numHead),
				speciesID: Number(ls.speciesId),
				classID: Number(ls.classId),
				isYoung: false,
				weight: Number(ls.old.avgWeight),
				exporterLine: ls.exporterLine,
				livestockCategoryId: ls.livestockCategoryId,
				age: ls.age,
				registeredEstablishmentID: ls.registeredEstablishmentID,
				pic: ls.pic,
			});
		}
		if (Number(ls.young.numHead) > 0) {
			lsArr.push({
				tagNumber: ls.tagNumber,
				tagTypeID: ls.tagTypeID,
				numberOfStock: Number(ls.young.numHead),
				speciesID: Number(ls.speciesId),
				classID: Number(ls.classId),
				isYoung: true,
				weight: Number(ls.young.avgWeight),
				exporterLine: ls.exporterLine,
				livestockCategoryId: ls.livestockCategoryId,
				age: ls.age,
				registeredEstablishmentID: ls.registeredEstablishmentID,
				pic: ls.pic,
			});
		}
		return lsArr;
	};

	let parseManagementPlan = (mp) => {
		return {
			speciesID: Number(mp.speciesId),
			classID: Number(mp.classId),
			managementPlanID: Number(mp.managementPlanId),
			numberOfHead: Number(mp.numberOfHead),
		};
	};

	let parseConsignment = () => {
		consignment.consignmentPorts = consignment.consignmentPorts.map((p) => {
			return parsePort(p);
		});

		return {
			ExistingConsignmentID:
				consignment.id && !isNaN(Number(consignment.id))
					? Number(consignment.id)
					: null,
			vesselID: Number(consignment.vesselID),
			lncNumber: consignment.lncNumber,
			exporterID: Number(consignment.exporterID),
			estimatedDepartureDateTime: consignment.estimatedDepartureDateTime,
			estimatedArrivalDateTime: consignment.estimatedArrivalDateTime,
			isUseAlternatePenSpacing: consignment.isUseAlternatePenSpacing,
			isIndependentObserverOnboard:
				consignment.independentObserverOnboard,
			departurePort: consignment.consignmentPorts[0],
			portStops: consignment.consignmentPorts.filter(
				(p, i, arr) => i > 0 && i < arr.length - 1 && p !== null
			),
			destinationPort: consignment.consignmentPorts.at(-1),
			departureLoadingFeed: parseFeed({
				pellets: consignment.departureLoadingPellets,
				grain: consignment.departureLoadingGrain,
				roughage: consignment.departureLoadingRoughage,
			}),
			robFeed: parseFeed({
				pellets: consignment.robPellets,
				grain: consignment.robGrain,
				roughage: consignment.robRoughage,
			}),
			requiresDailyReporting: consignment.requiresDailyReporting,
			competentStockpersons: consignment.competentStockpersons
				.map((csp) => parseCompetentStockpersons(csp))
				.filter((csp) => csp !== undefined),
			otherAAVIDs: consignment.otherAAVs.map((aav) => aav.id),
			otherAccreditedStockpersonIDs:
				consignment.otherAccreditedStockpersons.map((asp) => asp.id),
			livestock:
				consignment.livestockWithVoluntaryData &&
				consignment.livestockWithVoluntaryData.length > 0
					? consignment.livestockWithVoluntaryData
					: consignment.livestock
							.map((ls) => parseLivestock(ls))
							.flat(),
			managementPlans: consignment.managementPlans.map((mp) =>
				parseManagementPlan(mp)
			),
		};
	};
	let parsedConsignment = parseConsignment();
	return requests.post("/consignments/create", parsedConsignment);
};

export const submitPostEOVEditedConsignment = async (modifiedConsignmentData) => {

	let parsePort = (p) => {
		return {
			id: Number(p.id),
			portName: p.portName,
			isActive: p.isActive,
			countryID: Number(p.countryID),
			countryName: p.countryName,
			countryIsActive: p.countryIsActive,
		};
	};

	let parseFeed = (f) => {
		return {
			id: null,
			pellets: Number(f.pellets),
			grain: Number(f.grain),
			roughage: Number(f.roughage),
		};
	};

	let parseCompetentStockpersons = (csp) => {
		if (csp.firstName.length === 0 || csp.lastName.length === 0) return;
		return {
			firstName: csp.firstName,
			lastName: csp.lastName,
		};
	};

	let parseLivestock = (ls) => {
		let lsArr = [];
		if (Number(ls.old.numHead) > 0) {
			lsArr.push({
				tagNumber: ls.tagNumber,
				tagTypeID: ls.tagTypeID,
				numberOfStock: Number(ls.old.numHead),
				speciesID: Number(ls.speciesId),
				classID: Number(ls.classId),
				isYoung: false,
				weight: Number(ls.old.avgWeight),
				exporterLine: ls.exporterLine,
				livestockCategoryId: ls.livestockCategoryId,
				age: ls.age,
				registeredEstablishmentID: ls.registeredEstablishmentID,
				pic: ls.pic,
			});
		}
		if (Number(ls.young.numHead) > 0) {
			lsArr.push({
				tagNumber: ls.tagNumber,
				tagTypeID: ls.tagTypeID,
				numberOfStock: Number(ls.young.numHead),
				speciesID: Number(ls.speciesId),
				classID: Number(ls.classId),
				isYoung: true,
				weight: Number(ls.young.avgWeight),
				exporterLine: ls.exporterLine,
				livestockCategoryId: ls.livestockCategoryId,
				age: ls.age,
				registeredEstablishmentID: ls.registeredEstablishmentID,
				pic: ls.pic,
			});
		}
		return lsArr;
	};

	let parseManagementPlan = (mp) => {
		return {
			speciesID: Number(mp.speciesId),
			classID: Number(mp.classId),
			managementPlanID: Number(mp.managementPlanId),
			numberOfHead: Number(mp.numberOfHead),
		};
	};

	let parseConsignment = () => {
		modifiedConsignmentData.consignmentPorts = modifiedConsignmentData.consignmentPorts.map((p) => {
			return parsePort(p);
		});

		return {
			consignmentID: modifiedConsignmentData.id,
            exporterID: modifiedConsignmentData.exporter.id,
			vesselID: Number(modifiedConsignmentData.vesselID),
			lncNumber: modifiedConsignmentData.lncNumber,
			estimatedDepartureDateTime: modifiedConsignmentData.estimatedDepartureDateTime,
			estimatedArrivalDateTime: modifiedConsignmentData.estimatedArrivalDateTime,
			isUseAlternatePenSpacing: modifiedConsignmentData.isUseAlternatePenSpacing,
			isIndependentObserverOnboard: modifiedConsignmentData.independentObserverOnboard,
			departurePort: modifiedConsignmentData.consignmentPorts[0],
			portStops: modifiedConsignmentData.consignmentPorts.filter(
				(p, i, arr) => i > 0 && i < arr.length - 1 && p !== null
			),
			destinationPort: modifiedConsignmentData.consignmentPorts.at(-1),
			departureLoadingFeed: parseFeed({
				pellets: modifiedConsignmentData.departureLoadingPellets,
				grain: modifiedConsignmentData.departureLoadingGrain,
				roughage: modifiedConsignmentData.departureLoadingRoughage,
			}),
			robFeed: parseFeed({
				pellets: modifiedConsignmentData.robPellets,
				grain: modifiedConsignmentData.robGrain,
				roughage: modifiedConsignmentData.robRoughage,
			}),
			requiresDailyReporting: modifiedConsignmentData.requiresDailyReporting,
			competentStockpersons: modifiedConsignmentData.competentStockpersons
				.map((csp) => parseCompetentStockpersons(csp))
				.filter((csp) => csp !== undefined),
            otherAAVIDs: modifiedConsignmentData.otherAAVs.map((aav) => aav.id),
            otherAccreditedStockpersonIDs: modifiedConsignmentData.otherAccreditedStockpersons.map((asp) => asp.id),
			livestock:
                modifiedConsignmentData.livestockWithVoluntaryData &&
                modifiedConsignmentData.livestockWithVoluntaryData.length > 0
                        ? modifiedConsignmentData.livestockWithVoluntaryData
                        : modifiedConsignmentData.livestock
                                .map((ls) => parseLivestock(ls))
                                .flat(),
			managementPlans: modifiedConsignmentData.managementPlans.map((mp) =>
				parseManagementPlan(mp)
			),
		};
	};
    
	let parsedConsignment = parseConsignment();
	return requests.post("/consignments/posteovupdate", parsedConsignment);
};

export const updateConsignmentLivestock = async (consignment) => {
	let parseLivestock = (ls) => {
		let lsArr = [];
		if (Number(ls.old.numHead) > 0) {
			lsArr.push({
				tagNumber: ls.tagNumber,
				tagTypeID: ls.tagTypeID,
				numberOfStock: Number(ls.old.numHead),
				speciesID: Number(ls.speciesId),
				classID: Number(ls.classId),
				isYoung: false,
				weight: Number(ls.old.avgWeight),
				exporterLine: ls.exporterLine,
				livestockCategoryId: ls.livestockCategoryId,
				age: ls.age,
				registeredEstablishmentID: ls.registeredEstablishmentID,
				pic: ls.pic,
			});
		}
		if (Number(ls.young.numHead) > 0) {
			lsArr.push({
				tagNumber: ls.tagNumber,
				tagTypeID: ls.tagTypeID,
				numberOfStock: Number(ls.young.numHead),
				speciesID: Number(ls.speciesId),
				classID: Number(ls.classId),
				isYoung: true,
				weight: Number(ls.young.avgWeight),
				exporterLine: ls.exporterLine,
				livestockCategoryId: ls.livestockCategoryId,
				age: ls.age,
				registeredEstablishmentID: ls.registeredEstablishmentID,
				pic: ls.pic,
			});
		}
		return lsArr;
	};

	let parseManagementPlan = (mp) => {
		return {
			speciesID: Number(mp.speciesId),
			classID: Number(mp.classId),
			managementPlanID: Number(mp.managementPlanId),
			numberOfHead: Number(mp.numberOfHead),
		};
	};

	let parseConsignmentLivestock = () => {
		return {
			consignmentID:
				consignment.id && !isNaN(Number(consignment.id))
					? Number(consignment.id)
					: null,
			exporterID: Number(consignment.exporterID),
			livestock:
				consignment.livestockWithVoluntaryData &&
				consignment.livestockWithVoluntaryData.length > 0
					? consignment.livestockWithVoluntaryData
					: consignment.livestock
							.map((ls) => parseLivestock(ls))
							.flat(),
			managementPlans: consignment.managementPlans.map((mp) =>
				parseManagementPlan(mp)
			),
		};
	};
	let parsedConsignmentLivestock = parseConsignmentLivestock();
	return requests.post(
		"/consignments/livestock/update",
		parsedConsignmentLivestock
	);
};

export const getActiveConsignmentsForExporter = async (exporterId) =>
	await getAPI(`/consignments/exporter/${exporterId}`);
export const getActiveConsignmentsForExporterWithObsLocations = async (
	exporterId
) => await getAPI(`/consignments/exporter/withobslocations/${exporterId}`);
export const submitToDept = async (consignmentID) =>
	await requests.post(`/consignments/submittodept/${consignmentID}`);

export const getConsignmentDeparturePortsForConsignment = async (
	consignmentID
) => await getAPI(`/ports/departure/consignment/${consignmentID}`);

export const getDetailedConsignmentDeparturePort = async (
	consignmentDeparturePortID
) => await getAPI(`/ports/departure/detailed/${consignmentDeparturePortID}`);

export const getGroupedConsignmenLivestockForDeparturePort = async (
	consignmentDeparturePortID
) =>
	await getAPI(
		`/livestock/livestockloaded/grouped/${consignmentDeparturePortID}`
	);

export const getGroupedConsignmenPlannedLivestock = async (consignmentID) =>
	await getAPI(`/livestock/plannedlivestockloaded/grouped/${consignmentID}`);

export const getConsignmentDestinationPortsForConsignment = async (
	consignmentID
) => await getAPI(`/ports/destination/consignment/${consignmentID}`);

export const getLivestockDischargedForConsignment = async (consignmentID) =>
	await getAPI(`/livestock/alllivestockdischarged/${consignmentID}`);

export const getLivestockLoadedConsignment = async (consignmentID) =>
	await getAPI(`/livestock/alllivestockloaded/${consignmentID}`);

export const getAllDeparturePortsForCurrentActiveConsignments = async (
	consignmentIDs
) => {
	return await requests.post(`/ports/departure/consignments`, consignmentIDs);
};

export const getAllDestinationPortsForCurrentActiveConsignments = async (
	consignmentIDs
) => {
	return await requests.post(
		`/ports/destination/consignments`,
		consignmentIDs
	);
};

export const getHealthTreatmentsForConsignmentID = async (consignmentID) =>
	await getAPI(`healthtreatments/consignment/${consignmentID}`);
export const getHealthReportsForConsignmentID = async (consignmentID) =>
	await getAPI(`healthtreatments/consignment/${consignmentID}/reports`);
export const getHealthReportByID = async (reportID) =>
	await getAPI(`healthtreatments/report/${reportID}`);
export const getMortalitiesForConsignmentID = async (consignmentID) =>
	await getAPI(`mortalityconditions/consignment/${consignmentID}`);
export const getMortalityReportByID = async (reportID) =>
	await getAPI(`mortalityconditions/consignment/report/${reportID}`);
export const getBirthsForConsignmentID = async (consignmentID) =>
	await getAPI(`births/consignment/${consignmentID}`);
export const getAbortionsForConsignmentID = async (consignmentID) =>
	await getAPI(`abortions/consignment/${consignmentID}`);

export const getBirthAbortionReportsForConsignmentID = async (
	consignmentID
) => {
	let result1 = await getBirthsForConsignmentID(consignmentID);
	let result2 = await getAbortionsForConsignmentID(consignmentID);

	if (result1.data.length === 0 && result2.data.length === 0) return result1;
	else if (result1.data.length === 0) return result2;
	else if (result2.data.length === 0) return result1;
	else {
		result1.data = [...result1.data, ...result2.data];
		return result1;
	}
};
export const getConsignmentAnimalCount = async (consignmentID) =>
	await getAPI(`livestock/consignment/${consignmentID}/count`);

export const getFeedRemainingDetailsForConsignmentID = async (consignmentID) =>
	await getAPI(`feed/remainingdetails/consignment/${consignmentID}`);

export const getInitialFeedPlannedForConsignmentID = async (consignmentID) =>
	await getAPI(`feed/initialplanned/consignment/${consignmentID}`);

export const getAvgFeedPerHeadForConsignmentID = async (consignmentID) =>
	await getAPI(`feed/perhead/consignment/${consignmentID}`);

export const getAvgWaterConsumptionForConsignmentID = async (consignmentID) =>
	await getAPI(
		`observations/avgwaterconsumption/consignment/${consignmentID}`
	);

export const getDetailedConsignment = async (consignmentID) =>
	await getAPI(`consignments/${consignmentID}`);
export const getDetailedConsignmentWithObsLocations = async (consignmentID) =>
	await getAPI(`consignments/withobslocations/${consignmentID}`);

export const getConsignment = async (consignmentID) =>
	await getAPI(`consignments/basic/${consignmentID}`);

export const getEditHistoryForConsignment = async (consignmentID) =>
	await getAPI(`consignments/edithistory/${consignmentID}`);
export const getEditHistoryForREConsignment = async (consignmentID) =>
	await getAPI(
		`registeredestablishments/consignment/edithistory/${consignmentID}`
	);

export const getObservationLocationsForConsignmentID = async (consignmentID) =>
	await getAPI(`observations/locations/${consignmentID}`);

export const getMostRecentObservationForConsignmentID = async (consignmentID) =>
	await getAPI(`observations/mostrecent/${consignmentID}`);

export const getAllDailyReportsForConsignmentID = async (consignmentID) =>
	await getAPI(`observations/${consignmentID}`);
export const getAllConsignmentEventReportsForConsignmentID = async (
	consignmentID
) => await getAPI(`consignments/${consignmentID}/eventreports`);
export const getAllDetailedObservationsConsignmentID = async (consignmentID) =>
	await getAPI(`observations/detailed/${consignmentID}`);

export const getFeedDetail = async (feedDetailID) =>
	await getAPI(`feed/${feedDetailID}`);

export const getAAVsForConsignmentID = async (consignmentID) =>
	await getAPI(`consignments/${consignmentID}/assignedaavs`);

export const getAccreditedStockpersonsForConsignmentID = async (
	consignmentID
) => await getAPI(`consignments/${consignmentID}/accreditedstockpersons`);

export const getCompetentStockpersonsForConsignmentID = async (consignmentID) =>
	await getAPI(`consignments/${consignmentID}/competentstockpersons`);

export const getLivestockForConsignmentID = async (consignmentID) =>
	await getAPI(`consignments/${consignmentID}/livestockdetailed`);

export const getManagementPlansForConsignmentID = async (consignmentID) =>
	await getAPI(`consignments/${consignmentID}/managementplans`);

export const getConsignmentDaysForConsignmentID = async (consignmentID) =>
	await getAPI(`consignments/${consignmentID}/consignmentdays`);
export const getConsignmentDecksForConsignmentID = async (consignmentID) => {
	let lResponse = await getAPI(
		`consignments/${consignmentID}/consignmentdecks`
	);
	if (lResponse.status === 200 && lResponse.data) {
		lResponse.data = sortArrayInStandardDeckOrder(
			lResponse.data,
			"deckName",
			"isBridge"
		);
	}
	return lResponse;
};
export const getConsignmentDecksWithoutBridgeForConsignmentID = async (
	consignmentID
) => {
	let result = await getConsignmentDecksForConsignmentID(consignmentID);
	if (result.data) result.data = result.data.filter((deck) => !deck.isBridge);
	return result;
};
export const getSpeciesForConsignmentID = async (consignmentID) =>
	await getAPI(`consignments/${consignmentID}/species`);
export const getSpeciesClassForConsignmentID = async (consignmentID) =>
	await getAPI(`consignments/${consignmentID}/speciesclass`);
export const getHealthConditionL1ForConsignmentID = async (consignmentID) =>
	await getAPI(`consignments/${consignmentID}/healthconditionlevelone`);
export const getMortalityConditionL1ForConsignmentID = async (consignmentID) =>
	await getAPI(`consignments/${consignmentID}/mortalityconditionlevelone`);

export const getAllHistoricalConsignmentsVisibleForLoggedInUserID = async (
	exporterID
) => await getAPI(`consignments/historical/${exporterID}`);

export const patchDischargeReport = async (
	consignmentDestinationPortID,
	dischargeReportObj
) => {
	let results;
	await requests
		.patch(
			`/consignments/updatedischargereport/${consignmentDestinationPortID}`,
			dischargeReportObj
		)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const getEndOfVoyageReportForConsignment = async (consignmentID) =>
	await getAPI(
		`consignments/endofvoyagereportforconsignment/${consignmentID}`
	);

export const patchEndOfVoyageReport = async (eovReports) => {
	let results;
	await requests
		.patch(`/consignments/updateendofvoyagereport`, eovReports)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

// Only call if logged in user is exporter
export const setCompletedConsignmentDaysForConsignmentIDAsViewed = async (
	consignmentID
) => {
	return await requests.put(
		`consignments/${consignmentID}/setallcompletedconsignmentdayasviewed`
	);
};

// Only call if logged in user is exporter
export const setDischargeReportAsReviewed = async (
	consignmentDestinationPortID
) => {
	let results;
	await requests
		.post(
			`/consignments/destinationport/${consignmentDestinationPortID}/setreviewed`
		)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

// Only call if logged in user is exporter
export const setLoadingAndDepartureReportAsReviewed = async (
	consignmentDeparturePortID
) => {
	let results;
	await requests
		.post(
			`/consignments/departureport/${consignmentDeparturePortID}/setreviewed`
		)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

// Only call if logged in user is exporter
export const setEOVReportAsReviewed = async (eovReportID) => {
	let results;
	await requests
		.post(`/consignments/eovreport/${eovReportID}/setreviewed`)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const setConsigmentAsDeleted = async (consignmentID) => {
	let results;
	await requests
		.patch(`/consignments/setasdeleted/${consignmentID}`)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const getAllActiveConsignmentSummaries = async () =>
	await getAPI(`consignments/activeconsignmentsummaries`);

export const getAllCompletedConsignmentSummaries = async () =>
	await getAPI(`consignments/completedconsignmentsummaries`);
//#endregion

//#region Deck Records
export const getDeckRecordsForConsignmentDay = async (consignmentDayID) =>
	await getAPI(`/deckrecords/forday/${consignmentDayID}`);

export const getDeckRecordsForConsignment = async (consignmentID) =>
	await getAPI(`/deckrecords/consignment/${consignmentID}`);

export const setDeckRecordsDB = async (deckRecords) => {
	return await requests.post("/deckrecords/update", deckRecords);
};

export const setDeckRecord = async (deckRecords) => {
	return await requests.post("/deckrecords/update", deckRecords);
};

export const getPadDepthOptions = async () =>
	await getAPI(`/deckrecords/manurepaddepthtypes`);

export const getConsignmentDeckAnimals = async (consignmentID) =>
	await getAPI(`/deckrecords/alldeckanimals/${consignmentID}`);

//#endregion

//#region Deck Temperatures
export const getDeckTemperaturesForConsignmentID = async (consignmentID) =>
	await getAPI(`/decktemperatures/consignment/${consignmentID}/detailed`);

export const getDeckTemperatureForConsignmentDay = async (consignmentDayId) =>
	await getAPI(
		`/decktemperatures/consignmentday/${consignmentDayId}/detailed`
	);

export const getDeckTemperatureForConsignmentDayBasic = async (
	consignmentDayId
) => await getAPI(`/decktemperatures/consignmentday/${consignmentDayId}`);

export const updateDeckTemperature = async (deckTemperature) => {
	return await requests.post("/decktemperatures/update", deckTemperature);
};

//#endregion

//#region Edit logs
export const setEditLog = async (editLog) => {
	return await requests.post("/editlogs/", editLog);
};

export const setEditLogs = async (editLogs) => {
	return await requests.post("/editlogs/editlogs", editLogs);
};

export const addREConsignmentEditLogs = async (editLogs) => {
	return await requests.post("/editlogs/re/add", editLogs);
};

export const getEditPageReasons = async () => {
	return await getAPI("/editlogs/editreasons");
};
//#region

//#region Registered Establishments

export const getRegisteredEstablishments = async () =>
	await getAPI("/registeredestablishments");
//#endregion

//#region Admin Tools
export const addNewUser = async (
	firstName,
	lastName,
	isOrgAdmin,
	emailAddress,
	phoneNumber,
	accessMode
) => {
	let lUserObj = {
		FirstName: firstName,
		LastName: lastName,
		IsOrgAdmin: isOrgAdmin,
		EmailAddress: emailAddress,
		PhoneNumber: phoneNumber,
		CanWrite: accessMode,
	};
	let results;
	await requests
		.post("/Users/add", lUserObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const getAllExporters = async () => await getAPI(`/Exporters`);

export const getAllLiveCorpUsers = async () => await getAPI(`/Users/livecorp`);

export const getAllRegulatoryUsers = async () =>
	await getAPI(`/Users/regulatory`);

export const getUsersForExporter = async (exporterID) =>
	await getAPI(`/Users/exporters/${exporterID}`);

export const setUserPassword = async (encodedData, password) => {
	let lObj = { EncodedData: encodedData, Password: password };
	let results;
	await requests
		.post("/Users/setpassword", lObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const updateUser = async (
	id,
	firstName,
	lastName,
	isOrgAdmin,
	emailAddress,
	phoneNumber,
	accessMode,
	isActive
) => {
	let lUserObj = {
		ID: id,
		FirstName: firstName,
		LastName: lastName,
		IsOrgAdmin: isOrgAdmin,
		EmailAddress: emailAddress,
		PhoneNumber: phoneNumber,
		CanWrite: accessMode,
		IsActive: isActive,
	};
	let results;
	await requests
		.post("/Users/update", lUserObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const setAAV = async (
	id,
	firstName,
	lastName,
	accreditationNumber,
	emailAddress,
	phoneNumber,
	isActive
) => {
	let lUserObj = {
		ID: id,
		FirstName: firstName,
		LastName: lastName,
		AccreditationNumber: accreditationNumber,
		EmailAddress: emailAddress,
		PhoneNumber: phoneNumber,
		IsActive: isActive,
	};
	let results;
	await requests
		.post("/Users/aavs/set", lUserObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const setAccreditedStockPerson = async (
	id,
	firstName,
	lastName,
	accreditationNumber,
	emailAddress,
	phoneNumber,
	accreditationDateUTC,
	accreditationActive,
	isActive
) => {
	let lUserObj = {
		ID: id,
		FirstName: firstName,
		LastName: lastName,
		AccreditationNumber: accreditationNumber,
		EmailAddress: emailAddress,
		PhoneNumber: phoneNumber,
		InitialAccreditationDateUTC: accreditationDateUTC,
		AccreditationStatus: accreditationActive,
		IsActive: isActive,
	};
	let results;
	await requests
		.post("/Users/accreditedstockpeople/set", lUserObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const setLiveCorpUser = async (
	id,
	firstName,
	lastName,
	emailAddress,
	phoneNumber,
	isOrgAdmin,
	isActive
) => {
	let lUserObj = {
		ID: id,
		FirstName: firstName,
		LastName: lastName,
		EmailAddress: emailAddress,
		PhoneNumber: phoneNumber,
		IsOrgAdmin: isOrgAdmin,
		IsActive: isActive,
	};
	let results;
	await requests
		.post("/Users/livecorpuser/set", lUserObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const setRegulatoryUser = async (
	id,
	firstName,
	lastName,
	emailAddress,
	phoneNumber,
	isOrgAdmin,
	isActive
) => {
	let lUserObj = {
		ID: id,
		FirstName: firstName,
		LastName: lastName,
		EmailAddress: emailAddress,
		PhoneNumber: phoneNumber,
		IsOrgAdmin: isOrgAdmin,
		IsActive: isActive,
	};
	let results;
	await requests
		.post("/Users/regulatoryuser/set", lUserObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const sendPasswordResetEmail = async (emailAddress) => {
	let results;
	await requests
		.post("/Users/sendpasswordrestemail", emailAddress)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const setExportersRequireReviewStatus = async (
	exporterID,
	requiresReview
) => {
	let lObj = {
		ExporterID: exporterID,
		ReviewRequiredPriorToDeptSubmission: requiresReview,
	};
	let results;
	await requests
		.post("/Exporters/setrequirereviewstatus", lObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const getLIVEXCollectUsers = async () =>
	await getAPI("/users/allusersformanagement");

export const getUserTypes = async () => await getAPI("/users/types");

export const patchExporterUser = async (
	id,
	firstName,
	lastName,
	emailAddress,
	phoneNumber,
	isOrgAdmin,
	canWrite,
	isActive
) => {
	let lUserObj = {
		FirstName: firstName,
		LastName: lastName,
		EmailAddress: emailAddress,
		PhoneNumber: phoneNumber,
		IsOrgAdmin: isOrgAdmin,
		CanWrite: canWrite,
		IsActive: isActive,
	};
	let results;
	await requests
		.patch(`/Users/industry/patch/${id}`, lUserObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const postExporterUser = async (
	firstName,
	lastName,
	emailAddress,
	phoneNumber,
	isOrgAdmin,
	canWrite,
	isActive,
	exporterID
) => {
	let lUserObj = {
		FirstName: firstName,
		LastName: lastName,
		EmailAddress: emailAddress,
		PhoneNumber: phoneNumber,
		IsOrgAdmin: isOrgAdmin,
		CanWrite: canWrite,
		IsActive: isActive,
		ExporterID: exporterID,
	};
	let results;
	await requests
		.post(`/Users/industry/post`, lUserObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const patchLiveCorpUser = async (
	id,
	firstName,
	lastName,
	emailAddress,
	phoneNumber,
	isOrgAdmin,
	isActive
) => {
	let lUserObj = {
		ID: id,
		FirstName: firstName,
		LastName: lastName,
		EmailAddress: emailAddress,
		PhoneNumber: phoneNumber,
		IsOrgAdmin: isOrgAdmin,
		IsActive: isActive,
	};
	let results;
	await requests
		.patch(`/Users/livecorpuser/patch/${id}`, lUserObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const postLiveCorpUser = async (
	firstName,
	lastName,
	emailAddress,
	phoneNumber,
	isOrgAdmin,
	isActive
) => {
	let lUserObj = {
		ID: null,
		FirstName: firstName,
		LastName: lastName,
		EmailAddress: emailAddress,
		PhoneNumber: phoneNumber,
		IsOrgAdmin: isOrgAdmin,
		IsActive: isActive,
	};
	let results;
	await requests
		.post(`/Users/livecorpuser/post`, lUserObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const patchRegulatoryUser = async (
	id,
	firstName,
	lastName,
	emailAddress,
	phoneNumber,
	isOrgAdmin,
	isActive
) => {
	let lUserObj = {
		ID: id,
		FirstName: firstName,
		LastName: lastName,
		EmailAddress: emailAddress,
		PhoneNumber: phoneNumber,
		IsOrgAdmin: isOrgAdmin,
		IsActive: isActive,
	};
	let results;
	await requests
		.patch(`/Users/regulatoryuser/patch/${id}`, lUserObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const postRegulatoryUser = async (
	firstName,
	lastName,
	emailAddress,
	phoneNumber,
	isOrgAdmin,
	isActive
) => {
	let lUserObj = {
		ID: null,
		FirstName: firstName,
		LastName: lastName,
		EmailAddress: emailAddress,
		PhoneNumber: phoneNumber,
		IsOrgAdmin: isOrgAdmin,
		IsActive: isActive,
	};
	let results;
	await requests
		.post(`/Users/regulatoryuser/post`, lUserObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const patchAAVUser = async (
	id,
	firstName,
	lastName,
	emailAddress,
	phoneNumber,
	accreditationNumber,
	isActive
) => {
	let lUserObj = {
		ID: id,
		FirstName: firstName,
		LastName: lastName,
		AccreditationNumber: accreditationNumber,
		EmailAddress: emailAddress,
		PhoneNumber: phoneNumber,
		IsActive: isActive,
	};
	let results;
	await requests
		.patch(`/Users/aavs/patch/${id}`, lUserObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const postAAVUser = async (
	firstName,
	lastName,
	emailAddress,
	phoneNumber,
	accreditationNumber,
	isActive
) => {
	let lUserObj = {
		ID: null,
		FirstName: firstName,
		LastName: lastName,
		AccreditationNumber: accreditationNumber,
		EmailAddress: emailAddress,
		PhoneNumber: phoneNumber,
		IsActive: isActive,
	};
	let results;
	await requests
		.post(`/Users/aavs/post`, lUserObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const patchAccreditedStockPersonUser = async (
	id,
	firstName,
	lastName,
	emailAddress,
	phoneNumber,
	accreditationNumber,
	initialAccreditationDateUTC,
	accreditationStatus,
	isActive
) => {
	let lUserObj = {
		ID: id,
		FirstName: firstName,
		LastName: lastName,
		AccreditationNumber: accreditationNumber,
		EmailAddress: emailAddress,
		PhoneNumber: phoneNumber,
		IsActive: isActive,
		InitialAccreditationDateUTC: initialAccreditationDateUTC,
		AccreditationStatus: accreditationStatus,
	};
	let results;
	await requests
		.patch(`/Users/accreditedstockpeople/patch/${id}`, lUserObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const postAccreditedStockPersonUser = async (
	firstName,
	lastName,
	emailAddress,
	phoneNumber,
	accreditationNumber,
	initialAccreditationDateUTC,
	accreditationStatus,
	isActive
) => {
	let lUserObj = {
		ID: null,
		FirstName: firstName,
		LastName: lastName,
		AccreditationNumber: accreditationNumber,
		EmailAddress: emailAddress,
		PhoneNumber: phoneNumber,
		IsActive: isActive,
		InitialAccreditationDateUTC: initialAccreditationDateUTC,
		AccreditationStatus: accreditationStatus,
	};
	let results;
	await requests
		.post(`/Users/accreditedstockpeople/post`, lUserObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const reInviteAccreditedStockPersonUser = async (
	firstName,
	lastName,
	emailAddress,
	phoneNumber,
	accreditationNumber,
	initialAccreditationDateUTC,
	accreditationStatus,
	isActive
) => {
	let lUserObj = {
		ID: null,
		FirstName: firstName,
		LastName: lastName,
		AccreditationNumber: accreditationNumber,
		EmailAddress: emailAddress,
		PhoneNumber: phoneNumber,
		IsActive: isActive,
		InitialAccreditationDateUTC: initialAccreditationDateUTC,
		AccreditationStatus: accreditationStatus,
	};
	let results;
	await requests
		.post(`/Users/accreditedstockpeople/reinvite`, lUserObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const patchUserSettingsPage = async (
	id,
	firstName,
	lastName,
	emailAddress,
	phoneNumber
) => {
	let lUserObj = {
		ID: id,
		FirstName: firstName,
		LastName: lastName,
		EmailAddress: emailAddress,
		PhoneNumber: phoneNumber,
	};
	let results;
	await requests
		.patch(`/Users/loggedin/patch`, lUserObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const setPasswordSettingsPage = async (
	id,
	password,
	currentPassword
) => {
	let lUserObj = {
		ID: id,
		Password: password,
		CurrentPassword: currentPassword,
	};
	let results;
	await requests
		.post(`/Users/loggedin/setpassword`, lUserObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const getOrganisations = async () => await getAPI("/Organisations/");

export const getOrgTypes = async () => await getAPI("/Exporters/orgtypes");

export const getExporter = async (id) => await getAPI(`/Exporters/${id}`);

export const patchExporter = async (
	id,
	exporterName,
	phoneNumber,
	licenceNumber,
	licenceExpiryDate,
	isActive,
	authSigName,
	authSigEmail,
	authSigPhone
) => {
	let lExpObj = {
		ID: id,
		Name: exporterName,
		PhoneNumber: phoneNumber,
		LicenceNumber: licenceNumber,
		LicenceExpiryDateUTC: licenceExpiryDate,
		IsActive: isActive,
		AuthorisedSignatoryName: authSigName,
		AuthorisedSignatoryEmail: authSigEmail,
		AuthorisedSignatoryPhone: authSigPhone,
	};
	let results;
	await requests
		.patch(`/Exporters/patch/${id}`, lExpObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const postExporter = async (
	exporterName,
	phoneNumber,
	licenceNumber,
	licenceExpiryDate,
	isActive,
	authSigName,
	authSigEmail,
	authSigPhone
) => {
	let lExpObj = {
		ID: null,
		Name: exporterName,
		PhoneNumber: phoneNumber,
		LicenceNumber: licenceNumber,
		LicenceExpiryDateUTC: licenceExpiryDate,
		IsActive: isActive,
		AuthorisedSignatoryName: authSigName,
		AuthorisedSignatoryEmail: authSigEmail,
		AuthorisedSignatoryPhone: authSigPhone,
	};
	let results;
	await requests
		.post(`/Exporters/post`, lExpObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const patchRegisteredEstablishment = async (
	id,
	exporterName,
	phoneNumber,
	isActive,
	laes, // array of lae
	authSigName,
	authSigEmail,
	authSigPhone
) => {
	let lExpObj = {
		ID: id,
		Name: exporterName,
		PhoneNumber: phoneNumber,
		IsActive: isActive,
		LAEs: laes,
		AuthorisedSignatoryName: authSigName,
		AuthorisedSignatoryEmail: authSigEmail,
		AuthorisedSignatoryPhone: authSigPhone,
	};
	let results;
	await requests
		.patch(`/Exporters/registeredestablishment/patch/${id}`, lExpObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const postRegisteredEstablishment = async (
	name,
	phoneNumber,
	isActive,
	laes, // array of lae
	authSigName,
	authSigEmail,
	authSigPhone
) => {
	let lExpObj = {
		ID: null,
		Name: name,
		PhoneNumber: phoneNumber,
		LAEs: laes,
		IsActive: isActive,
		AuthorisedSignatoryName: authSigName,
		AuthorisedSignatoryEmail: authSigEmail,
		AuthorisedSignatoryPhone: authSigPhone,
	};
	let results;
	await requests
		.post(`/Exporters/registeredestablishment/post`, lExpObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const patchRegisteredEstablishmentExporter = async (
	id,
	exporterName,
	phoneNumber,
	isActive,
	licenceNumber,
	licenceExpiryDate,
	laes, // array of lae
	authSigName,
	authSigEmail,
	authSigPhone
) => {
	let lExpObj = {
		ID: id,
		Name: exporterName,
		PhoneNumber: phoneNumber,
		IsActive: isActive,
		LicenceNumber: licenceNumber,
		LicenceExpiryDateUTC: licenceExpiryDate,
		LAEs: laes,
		AuthorisedSignatoryName: authSigName,
		AuthorisedSignatoryEmail: authSigEmail,
		AuthorisedSignatoryPhone: authSigPhone,
	};
	let results;
	await requests
		.patch(
			`/Exporters/registeredestablishmentexporters/patch/${id}`,
			lExpObj
		)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const postRegisteredEstablishmentExporter = async (
	name,
	phoneNumber,
	isActive,
	licenceNumber,
	licenceExpiryDate,
	laes, // array of lae
	authSigName,
	authSigEmail,
	authSigPhone
) => {
	let lExpObj = {
		ID: null,
		Name: name,
		PhoneNumber: phoneNumber,
		LAEs: laes,
		IsActive: isActive,
		LicenceNumber: licenceNumber,
		LicenceExpiryDateUTC: licenceExpiryDate,
		AuthorisedSignatoryName: authSigName,
		AuthorisedSignatoryEmail: authSigEmail,
		AuthorisedSignatoryPhone: authSigPhone,
	};
	let results;
	await requests
		.post(`/Exporters/registeredestablishmentexporters/post`, lExpObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const getUser = async (id) => await getAPI(`/users/${id}`);

//#endregion

//#region Search

export const getConsignmentsIDsMatchingSearch = async (
	searchTerm,
	exporterID,
	searchSequence
) => {
	let results = await requests
		.post(`/consignments/search`, {
			searchQuery: searchTerm,
			exporterID: exporterID,
			searchSequence: searchSequence,
		})
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.log(err);
		});
	return results;
};

//#endregion

//#region Observations
export const getDetailedObservationForConsignmentDay = async (
	consignmentDayID
) => await getAPI(`observations/consignmentdaydetailed/${consignmentDayID}`);

export const patchObservation = async (id, observationObj) => {
	let results;
	await requests
		.patch(`/observations/patch/${id}`, observationObj)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

//#endregion

export const getPortByID = async (portID) => await getAPI(`ports/${portID}`);
//#region Registered Establishments

export const getRegisteredEstablishmentConsignmentsForRE = async (
	registeredEstablishmentID
) =>
	await getAPI(
		`registeredestablishments/consignments/${registeredEstablishmentID}`
	);

export const getRegisteredEstablishmentConsignmentsForREs = async (
	registeredEstablishmentIDs
) => {
	let results = await requests
		.post(
			"registeredestablishments/consignments",
			registeredEstablishmentIDs
		)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			return err;
		});
	return results;
};

export const getRegisteredEstablishmentConsignmentsForAllREs = async () =>
	await getAPI(`registeredestablishments/consignments/all`);

export const getREConsignmentByID = async (consignmentID) =>
	await getAPI(`registeredestablishments/consignment/${consignmentID}`);

export const deleteREConsignment = async (consignmentID) => {
	let results;
	await requests
		.patch(`/registeredestablishments/consignment/${consignmentID}/delete`)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err.response;
		});
	return results;
};

export const getLAENumberInfo = async (userID) =>
	await getAPI(`registeredestablishments/laeinfo/${userID}`);

export const getLAESForRegisteredEstablishments = async (reID) =>
	await getAPI(`registeredestablishments/laeinfo/exporter/${reID}`);

export const createRegisteredEstablishmentConsignment = async (consignment) => {
	let results;
	await requests
		.post(`registeredestablishments/consignment/create`, consignment)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err;
		});
	return results;
};

export const updateRegisteredEstablishmentConsignment = async (consignment) => {
	let results;
	await requests
		.post(`registeredestablishments/consignment/update`, consignment)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err;
		});
	return results;
};

export const submitREConsignmentToDepartment = async (submission) => {
	let results;
	await requests
		.patch(`registeredestablishments/setsubmitted`, submission)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err;
		});
	return results;
};

export const setRejectionsForConsignmentID = async (rejections) => {
	let results;
	await requests
		.post(`registeredestablishments/consignment/setrejections`, rejections)
		.then((res) => {
			results = res;
		})
		.catch((err) => {
			results = err;
		});
	return results;
};

export const getAllHistoricalConsignmentsVisibleForRegisteredEstablishmentExporterID =
	async (registeredEstablishmentExporterID) =>
		getAPI(
			`registeredestablishments/historicalconsignments/${registeredEstablishmentExporterID}`
		);

export const getAllREHistoricalConsignmentsVisible = async () =>
	getAPI(`registeredestablishments/historicalconsignments/all`);

export const searchRegisteredEstablishmentConsignments = async (
	searchTerm,
	exporterID,
	searchSequence
) => {
	let results = await requests
		.post(`/consignments/search`, {
			searchQuery: searchTerm,
			exporterID: exporterID,
			searchSequence: searchSequence,
		})
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.log(err);
		});
	return results;
};

export const getREConsignmentsIDsMatchingSearch = async (
	searchTerm,
	exporterID,
	searchSequence
) => {
	let results = await requests
		.post(`/registeredestablishments/historicalconsignments/search`, {
			searchQuery: searchTerm,
			exporterID: exporterID,
			searchSequence: searchSequence,
		})
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.log(err);
		});
	return results;
};

export const setMortalitiesForREConsignment = async (
	mortalities,
	consignmentID
) => {
	let results = await requests
		.post(
			`/registeredestablishments/consignment/${consignmentID}/setmortalities`,
			mortalities
		)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			return err;
		});
	return results;
};

export const updateREMortality = async (
	mortality,
	consignmentID,
	mortalityID
) => {
	let results = await requests
		.post(
			`/registeredestablishments/consignment/${consignmentID}/updatemortality/${mortalityID}`,
			mortality
		)
		.then((res) => {
			return res;
		})
		.catch((err) => {
			return err;
		});
	return results;
};

export const getREMortalityById = async (mortalityID) =>
	await getAPI(`registeredestablishments/mortality/${mortalityID}`);

export const getMortalityIDsMatchingSearch = async (
	searchTerm,
	exporterID,
	searchSequence
) => {
	let results = await requests
		.post(`registeredestablishments/mortalities/search`, {
			searchQuery: searchTerm,
			exporterID: exporterID,
			searchSequence: searchSequence,
		})
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.log(err);
		});
	return results;
};
//#endregion

//#region DB file import
export const uploadDBfile = async (file) => {
	let _data = new FormData();
	_data.append("file", file);

	let config = {
		method: "put",
		maxBodyLength: Infinity,
		url: apiConfig.baseURL + "/sync/upload",
		headers: {
			"x-api-key": "sZksEIse1vfrTLc1QkDnRTTPOAYBomvY1ub/HIYgIkM=",
			Authorization:
				"Bearer " + getSessionLoggedInUser()?.authDetails?.accessToken,
			"Content-Type": file.type,
		},
		maxRedirects: 0,
		data: _data,
		timeout: 300000,
		responseType: "arraybuffer",
		httpsAgent: null,
		proxy: false,
	};

	return axios.request(config);
};
//#endregion
