import { format, differenceInHours, isAfter, subDays } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import moment from "moment-timezone";
import tz_lookup from "tz-lookup";
import { CurrentAndExpectedDayNumbers } from "../classes/CurrentAndExpectedDayNumbers";
import { sortByProperty, sortByPropertyDesc } from "./ListUtils";
import { getPortByID } from "../api/apiAccess";


export const MortalityFactors = [
  {id: 1, label: "Dominance / aggression", isChecked: false},
  {id: 2, label: "Feed issues", isChecked: false},
  {id: 3, label: "Water issues", isChecked: false},
  {id: 4, label: "Weather conditions", isChecked: false},
  {id: 5, label: "Ventilation issues", isChecked: false},
  {id: 6, label: "Shy feeder", isChecked: false},
  {id: 7, label: "Other", isChecked: false},
];

export const goto = (navigate, page, params) => {
  navigate("/" + page, params);
};

export const uniqueArray = (arr) => {
  return [...new Set(arr.map((item) => JSON.stringify(item)))].map((item) => JSON.parse(item));
};

export const combineArrays = (arr1, arr2, callback) => {
  let arr = [];

  for (let i = 0; i < arr1.length; i++) {
    for (let j = 0; j < arr2.length; j++) {
      if (callback) arr.push(callback(arr1[i], arr2[j]));
      else arr.push([arr1[i], arr2[j]]);
    }
  }

  return arr;
};

export const isEmptyString = (input) => {
  if (input === null || input === undefined || (typeof input === "string" && input.trim() === "")) {
    return true;
  }
  return false;
};

export const isNaN_Or_NaNstr = (input) => {
  if (isNaN(input) || (typeof input === "string" && input.trim() === "NaN")) {
    return true;
  }
  return false;
}

export const areArraysEqual = (arr1, arr2) => {
  // Check if the arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Compare each element in the sorted arrays
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  // All elements are equal
  return true;
};

export const setItemInArray = (arr, index, item) => {
  let _arr = [...arr];
  _arr[index] = item;
  return _arr;
};

export const isEqual = (obj1, obj2) => {
  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if the number of keys is the same
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Compare each key-value pair in the objects
  for (let key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  // All key-value pairs are the same
  return true;
};

export const isExistInArray = (arr, obj) => {
  return arr.some((item) => isEqual(item, obj));
};

export const generateRandomString = (length) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
};

export const toMultiLevelStringOfCondition = (condition) => {
  if (condition) {
    let str = "";
    if (condition.levelOne && condition.levelOne.conditionName) str += condition.levelOne.conditionName + "\n";
    if (condition.levelTwo && condition.levelTwo.conditionName) str += "\u00A0 ".repeat(2) + condition.levelTwo.conditionName + "\n";
    if (condition.levelThree && condition.levelThree.conditionName) str += "\u00A0 ".repeat(4) + condition.levelThree.conditionName + "\n";
    return str;
  }
  return "";
};

export const isConditionIncludes = (condition, str) => {
  if (str === null) return false;

  if (condition) {
    if (condition.levelOne && condition.levelOne.conditionName && condition.levelOne.conditionName.toLowerCase().includes(str.toLowerCase()))
      return true;
    if (condition.levelTwo && condition.levelTwo.conditionName && condition.levelTwo.conditionName.toLowerCase().includes(str.toLowerCase()))
      return true;
    if (condition.levelThree && condition.levelThree.conditionName && condition.levelThree.conditionName.toLowerCase().includes(str.toLowerCase()))
      return true;
  }
  return false;
};

export const getMedicationInfoFromHealthReport = (healthReport) => {
  let medications = healthReport.treatment.medication;
  let info = [];
  for (let i = 0; i < medications.length; i++) {
    let medication = medications[i];
    if (medication.medication) {
      info.push([
        medication.medication[medication.medication.length - 1].name + " " + medication.dosage + "ml",
        medication.freeText ? medication.freeText : "",
      ]);
    }
  }
  return info;
};

export const getMillisecondsDifference = (d1, d2) => {
  return Math.abs(d1.getTime() - d2.getTime());
};

export const isValidTZLocation = (latitude, longitude) => {
  return (
    latitude !== undefined &&
    longitude !== undefined &&
    latitude !== 0 &&
    longitude !== 0 &&
    latitude >= -90 &&
    latitude <= 90 &&
    longitude >= -180 &&
    longitude <= 180
  );
};

export const isValidTZLocationOfPort = (port) => {
  return isValidTZLocation(port?.latitude, port?.longitude);
};

export const getPortLocalDatetime = (port, datetime, _format = "DD/MM/yyyy HH:mm") => {
  if (port && isValidTZLocationOfPort(port)) {
    //console.log("getPortLocalDatetime dd: ",datetime,tz_lookup(port?.latitude, port?.longitude),moment.utc(datetime).tz(tz_lookup(port?.latitude, port?.longitude)).format(_format));
    return moment.utc(datetime).tz(tz_lookup(port?.latitude, port?.longitude)).format(_format) + " (" + port.portName + " local time)";
  }
  else return "<Unknown local date time since invalid port Location>";
};

export const getPortLocalDatetimeNoLabel = (port, datetime, _format = "DD/MM/yyyy HH:mm") => {
  if (port && isValidTZLocationOfPort(port)) {
    //console.log("getPortLocalDatetime dd: ",datetime,tz_lookup(port?.latitude, port?.longitude),moment.utc(datetime).tz(tz_lookup(port?.latitude, port?.longitude)).format(_format));
    return moment.utc(datetime).tz(tz_lookup(port?.latitude, port?.longitude)).format(_format);
  }
  else return "<Unknown local date time since invalid port Location>";
};

export const getPortDateTimeUTC = (port, datetime, _format = "DD/MM/yyyy HH:mm") => {
  if (port && isValidTZLocationOfPort(port)) {
    return moment.utc(datetime).format(_format);
  }
  else return "<Unknown UTC date time since invalid port Location>";
}

export const getPortTimezone = (port) => {
  if (port && isValidTZLocationOfPort(port)) {
    return tz_lookup(port?.latitude, port?.longitude);
  }
  return null;
}

export const convertDateToCooridnatesTimezone = (latitude, longitude, datetimeLocal, formart = "dd/MM/yyyy HH:mm") => {
  let lTimezone = tz_lookup(latitude, longitude);
  let lConverted = utcToZonedTime(datetimeLocal.toISOString(), lTimezone);
  return lConverted;
};

// Any logged in non regulatory user can view voluntary data
export const canViewVoluntaryData = (user) => {
  let lCanViewVoluntaryData = false;

  if (user !== undefined) {
    let lUserTypeID = user.userTypeID;

    if (lUserTypeID !== undefined && lUserTypeID !== 2) {
      lCanViewVoluntaryData = true;
    }
  }

  return lCanViewVoluntaryData;
}


export const formatInTimeZone = (date, fmt, tz) => {
  if (!date) return undefined;
  return format(utcToZonedTime(date, tz),  fmt,  { timeZone: tz });
}

export const getCurrentDayAndEndDayForVoyage = async (aLoggedInUserTypeID, aVoyage) => {
  if (aVoyage !== null && aVoyage !== undefined && aLoggedInUserTypeID !== null && aLoggedInUserTypeID !== undefined) {
    // Group data by type
    let lConsignments = [];
    let lDeparturePorts = [];
    let lDestinationPorts = [];
    let lObservations = [];
    let lConsignmentDays = [];

    if (aVoyage.detailedConsignments && aVoyage.detailedConsignments.length > 0) {
      aVoyage.detailedConsignments.forEach((aDetailedConsignment) => {
        if (aDetailedConsignment.Consignment) {
          lConsignments.push(aDetailedConsignment.Consignment);
        }
        if (aDetailedConsignment.DeparturePorts) {
          lDeparturePorts = lDeparturePorts.concat(aDetailedConsignment.DeparturePorts);
        }
        if (aDetailedConsignment.DestinationPorts) {
          lDestinationPorts = lDestinationPorts.concat(aDetailedConsignment.DestinationPorts);
        }
        if (aDetailedConsignment.ConsignmentDays) {
          aDetailedConsignment.ConsignmentDays.forEach((aConsignmentDay) => {
            if (aConsignmentDay.observation) {
              lObservations.push(aConsignmentDay.observation);
            }

            lConsignmentDays = lConsignmentDays.concat(aDetailedConsignment.ConsignmentDays);
          })
        }
      })

      let lCurrentAndEndDay = await getCurrentAndEndDay(aLoggedInUserTypeID, lConsignments, lDeparturePorts, lDestinationPorts, lObservations, lConsignmentDays);
      return lCurrentAndEndDay;
    }
    else {
      return new CurrentAndExpectedDayNumbers(null, null);
    }
  }
}

export const getCurrentDayAndEndDayForConsignment = async (aLoggedInUserTypeID, aConsignment) => {
  if (aConsignment !== null && aConsignment !== undefined && aLoggedInUserTypeID !== null && aLoggedInUserTypeID !== undefined) {
    let lConsignments = [];
    let lDeparturePorts = [];
    let lDestinationPorts = [];
    let lObservations = [];
    let lConsignmentDays = [];

    if (aConsignment.Consignment) {
      lConsignments.push(aConsignment.Consignment);
    }
    if (aConsignment.DeparturePorts) {
      lDeparturePorts = lDeparturePorts.concat(aConsignment.DeparturePorts);
    }
    if (aConsignment.DestinationPorts) {
      lDestinationPorts = lDestinationPorts.concat(aConsignment.DestinationPorts);
    }
    if (aConsignment.ConsignmentDays) {
      aConsignment.ConsignmentDays.forEach((aConsignmentDay) => {
        if (aConsignmentDay.observation) {
          lObservations.push(aConsignmentDay.observation);
        }
      })

      lConsignmentDays = lConsignmentDays.concat(aConsignment.ConsignmentDays);
    }
    let lCurrentAndEndDay = await getCurrentAndEndDay(aLoggedInUserTypeID, lConsignments, lDeparturePorts, lDestinationPorts, lObservations, lConsignmentDays);

    return lCurrentAndEndDay;
  }
  else {
    return new CurrentAndExpectedDayNumbers(null, null);
  }
}

const getCurrentAndEndDay = async (aLoggedInUserTypeID, aConsignments, aDeparturePorts, aDestinationPorts, aObservations, aConsignmentDays) => {
  const getPort = async (aPortID) => {
    let lPort = await getPortByID(aPortID);
    return lPort.data;
  }

  // Calculate Current Day and Expected Number of Days
  let lCurrentDayNumber = null;
  let lExepctedNumberOfDays = null;

  // If the logged in user is regulatory, 
  // we can only use departure ports, destination ports and observations that occurred on a day that has been submitted
  if (aLoggedInUserTypeID === 2) {
    let lSubmittedDeparutrePorts = [];
    let lSubmittedDestinationPorts = [];
    let lSubmittedObservations = [];

    if (aConsignmentDays && aConsignmentDays.length > 0) {
      let lSubmittedConsignmentDayIDs = aConsignmentDays.map(cd => cd.id);

      if (aDeparturePorts && aDeparturePorts.length > 0) {
        lSubmittedDeparutrePorts = [...aDeparturePorts].filter(dp => lSubmittedConsignmentDayIDs.includes(dp.ConsignmentDayID));
      }
      if (aDestinationPorts && aDestinationPorts.length > 0) {
        lSubmittedDestinationPorts = [...aDestinationPorts].filter(dp => lSubmittedConsignmentDayIDs.includes(dp.ConsignmentDayID));
      }
      if (aObservations && aObservations.length > 0) {
        lSubmittedObservations = [...aObservations].filter(obs => lSubmittedConsignmentDayIDs.includes(obs.ConsignmentDayID));
      }
    }

    aDeparturePorts = lSubmittedDeparutrePorts;
    aDestinationPorts = lSubmittedDestinationPorts;
    aObservations = lSubmittedObservations;
  }

  // Get Start and End Date
  let lStartDate = getStartDateForVoyage(aConsignments, aDeparturePorts);
  let lEndDate = getEndDateForVoyage(aConsignments, aDestinationPorts, aObservations);

  // Set current day number to the most recent observation day number
  if (aConsignmentDays && aConsignmentDays.length > 0) {
    let lMostRecentDayNumber = aConsignmentDays.reduce((maxDayNumber, dayToCheck) => {
      let lCurrentDayNumber = dayToCheck.dayNumber;
      if (lCurrentDayNumber > maxDayNumber) {
        return lCurrentDayNumber;
      }
      else {
        return maxDayNumber;
      }
    }, 1);

    lCurrentDayNumber = lMostRecentDayNumber;
  }

  // Calculate expected number of days
  if (lStartDate && lEndDate && aObservations && aObservations.length > 0) {

    // CALCULATE THE END DATE OF THE FIRST DAY
    let lFirstObservation = sortByProperty(aObservations, "VoyageDay.dayNumber")[0];
    // The first day starts when loading commenced and ends at mid day the following day
    // Convert first day start date from the system timezone to the timezone of the first observation
    let lMomentStartDate = moment(new Date(lStartDate));
    let lMomentStartDateShipsTimezone = lMomentStartDate.tz(tz_lookup(lFirstObservation.latitude, lFirstObservation.longitude));

    // Add 1 day to the start date then set the hours to 12 to get the end of day 1
    let lFirstDayEnd = moment(lMomentStartDateShipsTimezone).add(1, 'days');
    lFirstDayEnd = lFirstDayEnd.set({ hour: 12, minute: 0, second: 0, millisecond: 0 })

    // Set the number of days to 1 for the start date
    let lNumberOfDays = 1;

    // CALCULATE HOW MANY FULL DAYS BETWEEN THE END OF THE FIRST DAY AND THE END DATE
    // calling to date seems to convert lFirstDayEnd back to the system timezone
    let lDiffernceInHours = differenceInHours(lEndDate, lFirstDayEnd.toDate());
    let lDiffInDays = lDiffernceInHours / 24;
    let lRounded = Math.floor(lDiffInDays);
    lNumberOfDays += lRounded;

    // IF THE FINAL DISCHARGE COMPLETED DATE WAS AFTER MID DAY LOCAL TIME ADD AN EXTRA DAY
    // Convert end date from system time to the timezone of the destination port
    let lFinalDestinationPort = sortByPropertyDesc(aDestinationPorts, "DischargeCompletedDateUTC")[0];
    // get location of final destination port
    let lPort = await getPort(lFinalDestinationPort.PortID);
    let lEndDateInDeparturePortTimeZone = moment(lEndDate);
    lEndDateInDeparturePortTimeZone = lEndDateInDeparturePortTimeZone.tz(tz_lookup(lPort.latitude, lPort.longitude));

    if (lEndDateInDeparturePortTimeZone.hour() >= 12) {
      lNumberOfDays += 1;
    }
    lExepctedNumberOfDays = lNumberOfDays;

  }

  return new CurrentAndExpectedDayNumbers(lCurrentDayNumber, lExepctedNumberOfDays);
}

const getStartDateForVoyage = (aConsignments, aDeparturePorts) => {
  // if consignment is null, undeifned ot empty  we cant determine the date
  let lStartDate = null;

  if (aConsignments !== null && aConsignments !== undefined && aConsignments.length > 0) {
    // If we have a loading report, the start date is when loading commenced
    if (aDeparturePorts !== null && aDeparturePorts !== undefined && aDeparturePorts.length > 0 && aDeparturePorts.some(dp => dp.LoadingCommencedDateUTC)) {
      // remove any departure ports that do not have a loading commenced date
      let lFilteredDeparturePorts = [...aDeparturePorts].filter(dp => dp.LoadingCommencedDateUTC != null && dp.LoadingCommencedDateUTC != undefined);

      // get oldest loading commenced date
      lStartDate = lFilteredDeparturePorts.reduce((minDate, obj) => {
        let lCurrentDate = new Date(obj.LoadingCommencedDateUTC);
        if (lCurrentDate < minDate) {
          return lCurrentDate;
        }
        else {
          return minDate;
        }
      }, new Date(lFilteredDeparturePorts[0].LoadingCommencedDateUTC));
    }
    else { // use the earliest esitmated start date of the consignment      
      lStartDate = aConsignments.reduce((minDate, obj) => {
        let lCurrentDate = new Date(obj.estimatedDepartureDateUTC);
        if (lCurrentDate < minDate) {
          return lCurrentDate;
        }
        else {
          return minDate;
        }
      }, new Date(aConsignments[0].estimatedDepartureDateUTC));
    }
  }
  return lStartDate;
}

const getEndDateForVoyage = (aConsignments, aDestinationPorts, aObservations) => {
  // if consignment is null, undeifned ot empty  we cant determine the date
  let lEndDate = null;

  if (aConsignments !== null && aConsignments !== undefined) {

    let lLatestArrivalDate = aConsignments.reduce((maxDate, obj) => {
      let lCurrentDate = new Date(obj.estimatedArrivalDateUTC);
      if (lCurrentDate > maxDate) {
        return lCurrentDate;
      }
      else {
        return maxDate;
      }
    }, new Date(aConsignments[0].estimatedArrivalDateUTC));
    if (aDestinationPorts !== null && aDestinationPorts !== undefined && aDestinationPorts.length > 0) {
      // If all discharge reports have been completed use the latest departure completed date as the consignment end date
      if (aDestinationPorts?.every(dp => dp.DischargeCompletedDateUTC)) {
        lEndDate = aDestinationPorts.reduce((maxDate, obj) => {
          let lCurrentDate = new Date(obj.DischargeCompletedDateUTC);
          if (lCurrentDate > maxDate) {
            return lCurrentDate;
          }
          else {
            return maxDate;
          }
        }, new Date(aDestinationPorts[0].DischargeCompletedDateUTC));
      }
      else if (aObservations !== null && aObservations !== undefined && aObservations.length > 0) {
        // check if there is only one remaining destination port that does not have a discharge completed date, if so that is the final port
        let lIncompleteDestinationPorts = [...aDestinationPorts].filter(dp => !dp.DischargeCompletedDateUTC);
        let lUniqueDestinationPortIDs = [...new Set(lIncompleteDestinationPorts.map(dp => dp.PortID))];

        let lLatestObservationArrivalDate = aObservations.reduce((maxDate, obj) => {
          let lCurrentDate = new Date(obj.estimatedArrivalDateUTC);
          if (lCurrentDate > maxDate) {
            return lCurrentDate;
          }
          else {
            return maxDate;
          }
        }, new Date(aObservations[0].estimatedArrivalDateUTC));

        if (lUniqueDestinationPortIDs.length === 1) {
          let lFinalPortID = lUniqueDestinationPortIDs[0];

          // get all observations with thr next port = the final port
          let lFinalDestinationPortObservations = [...aObservations].filter(obs => obs.nextPortID === lFinalPortID);
          // If there are any use the latest estimated arrival date
          if (lFinalDestinationPortObservations.length > 0) {
            lEndDate = lFinalDestinationPortObservations.reduce((maxDate, obj) => {
              let lCurrentDate = new Date(obj.estimatedArrivalDateUTC);
              if (lCurrentDate > maxDate) {
                return lCurrentDate;
              }
              else {
                return maxDate;
              }
            }, new Date(lFinalDestinationPortObservations[0].estimatedArrivalDateUTC));
          }
          else {
            // get latest out of observations estimated arrival date and consignments estimated arrival date
            lEndDate = lLatestObservationArrivalDate > lLatestArrivalDate ? lLatestObservationArrivalDate : lLatestArrivalDate;
          }
        }
        else {
          // get latest out of observations estimated arrival date and consignments estimated arrival date
          lEndDate = lLatestObservationArrivalDate > lLatestArrivalDate ? lLatestObservationArrivalDate : lLatestArrivalDate;
        }
      }
      else { // end date is the latest estimated arriaval date out of all consignments
        lEndDate = lLatestArrivalDate
      }
    }
    else { // end date is the latest estimated arriaval date out of all consignments
      lEndDate = lLatestArrivalDate
    }
  }

  return lEndDate;
}

// Formats a value up to the specified precision (rounds the value)
// If the value input is not valid it will return the value
// e.g if max precision = 2
// 2 = 2
// 1.999 = 2
/// 2.1 = 2.1
export const formatUpToSpecifiedPrecision = (value, maxPrecision) => {
  let lFormatted = "-";
  if (value != null && value != undefined && !isNaN(value)) {
    // the + converts the string output of toFIxed back to a float which removes unecessary decimal places
    lFormatted = +parseFloat(value).toFixed(maxPrecision);
  }
  return lFormatted;
}

export const isReportEditable = (userCtx, allowEditing) => {
  return userCtx?.user?.writeAccess === true && ((allowEditing !== undefined && allowEditing === true) || allowEditing === undefined);
};

export const isOverDays = (dateTime,daysNumber = 14) => {
  if(dateTime === undefined || dateTime === null) return false;
  // Convert the input to a Date object if it's not already
  const inputDate = new Date(dateTime);

  // Get the current date and time
  const now = new Date();

  // Calculate the difference in milliseconds
  const diffInMs = now - inputDate;

  // Convert the difference from milliseconds to days
  const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

  // Check if the difference is greater than 14 days
  return diffInDays > daysNumber;
}

export const isOver14Days = (dateTime) => {
  return isOverDays(dateTime, 14);
}

export const isOver28Days = (dateTime) => {
  return isOverDays(dateTime, 28);
}

export const isOver365Days = (dateTime) => {
  return isOverDays(dateTime, 365);
}

export const isWithin28Days = (date) => {
    const twentyEightDaysAgo = subDays(new Date(), 28);
    return isAfter(date, twentyEightDaysAgo);
}

export const isWithinSpecifiedNumberOfDays = (date, numberOfDays) => {
    const numberOfDaysPrior = subDays(new Date(), numberOfDays);
    return isAfter(date, numberOfDaysPrior);
}