import { useEffect, useState, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col, Form, Button, Stack, Alert, Spinner } from "react-bootstrap";
import LoggedInContainer from "../../layout/LoggedInContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { getDeckRecordsForConsignmentDay, setDeckRecordsDB } from "../../api/apiAccess";
import { DetailedDeckRecord } from "../../classes/DetailedDeckRecord";
import FeedingBehaviour from "../../components/DeckRecords/FeedingBehaviour";
import { sortArrayInStandardDeckOrder } from "../../utils/ListUtils";
import '../../css/deckrecords.css';
import { getEditPageReasons } from "../../api/apiAccess";
import EditLogModal from "../../components/EditLogModal";
import { UserContext } from "../..";
import ReadWriteButton from "../../components/ReadWriteElements/ReadWriteButton";
import { isReportEditable } from "../../utils/common";

// See comment in DeckRecord.js in the classes folder in regards to empty decks
const Edit_Deck_Record_Feeding_Behaviour = () => {
    const { state } = useLocation(); // todo get voyage day has a voyageDay object
    const inputFormContainer = useRef();
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(true);
    const [deckRecords, setDeckRecords] = useState([]);
    const [hasData, setHasData] = useState(false);
    const [hasSaveError, setHasSaveError] = useState(false);
    const [hasLoadError, setHasLoadError] = useState(false);
    const [showSaveSuccess, setShowSaveSuccess] = useState(false);
    const [triggerShowInvalid, setTriggerShowInvalid] = useState(-1);
    const [editModalShow, setEditModalShow] = useState(false);
    const [editPageReasons, setEditPageReasons] = useState([]);

    const groupByDecks = (detailedDeckRecords) => {
        let lGrouped = [];

        detailedDeckRecords.forEach(dr => {
            let lGroup = lGrouped.find(gr => gr.deckID === dr.DeckRecord.consignmentDeckID)
            if (!lGroup) {
                let lNewDeck = { deckID: dr.DeckRecord.consignmentDeckID, name: dr.ConsignmentDeck.deckName, isBridge: dr.ConsignmentDeck.isBridge, detailedDeckRecords: [] }
                lGroup = lNewDeck;
                lGrouped.push(lNewDeck);
            }
            // on load data should be valid
            dr.DeckRecord.isValid = true;
            lGroup.detailedDeckRecords.push(dr);
        })

        // sort decks
        lGrouped = sortArrayInStandardDeckOrder(lGrouped, "name", "isBridge");
        return lGrouped;
    };

    useEffect(() => {
        const fetchPageData = async () => {
            let lPromises = [
                getEditPageReasons(),
                getDeckRecordsForConsignmentDay(state.consignmentDay.id)
            ];

            Promise.all(lPromises)
                .then(response => {
                    if (response && response.every(resp => resp.status === 200)) {

                        let reasons = response[0].data;
                        setEditPageReasons(reasons);

                        let detailedDeckRecords = [];
                        response[1].data.forEach(d => {
                            detailedDeckRecords.push(new DetailedDeckRecord(d));
                        })

                        let lGrouped = groupByDecks(detailedDeckRecords);

                        setDeckRecords(lGrouped);

                        return true;
                    }
                    else {
                        return false;
                    }
                }).then(result => {
                    setHasData(result);
                    setHasLoadError(!result)
                    setIsLoading(false);
                })
                .catch(error => {
                    setHasLoadError(true);
                    setIsLoading(false);
                })
        }

        fetchPageData();
    }, []);

    const onUpdate = (deckID, deckRecordID, percentNoInterest, percentClimbingSmothering, percentInterested, isValid) => {
        let lUpdatedRecords = [...deckRecords];
        let lDeckGroupToUpdate = lUpdatedRecords.find(deck => deck.deckID === deckID);
        if (lDeckGroupToUpdate) {
            let lRecordToUpdate = lDeckGroupToUpdate.detailedDeckRecords.find(gr => gr.DeckRecord.id === deckRecordID);
            if (lRecordToUpdate) {
                if (lRecordToUpdate.DeckRecord.isEmpty === false) { // can't update empty deck
                    lRecordToUpdate.DeckRecord.isValid = isValid;
                    lRecordToUpdate.DeckRecord.feedingBehaviourNoInterestPercent = percentNoInterest;
                    lRecordToUpdate.DeckRecord.feedingBehaviourClimbingSmotheringPercent = percentClimbingSmothering;
                    lRecordToUpdate.DeckRecord.feedingBehaviourInterestedPercent = percentInterested;
                    setDeckRecords(lUpdatedRecords);
                }
            }
        }
    }

    const goBack = () => {
        navigate(`/viewhistorydata/${state.consignmentDay.consignmentId}`, { state: { focusedTab: "feeding behaviour", selectedColumnIndex: state?.selectedColumnIndex, editable: isReportEditable(userCtx, state.allowEditing) } })
    }

    const validateAndSaveShowEditModal = () => {
        // Validate
        let lIsValid = true;
        deckRecords.forEach(deck => {
            deck.detailedDeckRecords.forEach(dr => {
                if (dr.DeckRecord.isValid === false) {
                    lIsValid = false;
                }
            })
        })

        if (lIsValid === true) {
            setEditModalShow(true);
        }
        else {
            // add invalid style to input controls
            setTriggerShowInvalid(triggerShowInvalid + 1);
            window.scrollTo(0, inputFormContainer.current.offsetTop);
        }
    }

    const writeToDB = async () => {
        // should already be valdiated at this point
        let lRecordsToSave = [];

        for (const deck of deckRecords) {
            for (const deckRecord of deck.detailedDeckRecords) {
                lRecordsToSave.push(deckRecord.DeckRecord);
            }
        }

        let result = await setDeckRecordsDB(lRecordsToSave);
        if (result.status === 200) {
            navigate(`/viewhistorydata/${state.consignmentDay.consignmentId}`, { state: { focusedTab: "feeding behaviour", selectedColumnIndex: state?.selectedColumnIndex, editable: isReportEditable(userCtx,state.allowEditing) } });
            // setShowSaveSuccess(true);
            // setHasSaveError(false);
            // setTimeout(() => {
            //     setShowSaveSuccess(false);
            // }, 15000);
        }
        else {
            setShowSaveSuccess(false);
            setHasSaveError(true);
        }
    }

    const onEditModalClosed = () => {
        setEditModalShow(false);
    }

    const onEditModalSaveSuccess = async () => {
        setEditModalShow(false);
        await writeToDB();
    }

    const onEditModalSaveFailed = () => {
        // Show logging error
    }

    return (
        <LoggedInContainer>
            <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
                <Row className="upper-panel mx-0">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", paddingTop: "56px" }}>
                                    <div style={{ lineHeight: "53px", fontSize: "35px" }}>
                                        {isReportEditable(userCtx, state.allowEditing) ? "Edit Deck Records - Feeding Behaviour" : "View Deck Records - Feeding Behaviour"}
                                    </div>
                                    <div><em>Day {state.consignmentDay.dayNumber}</em></div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Row>

                <Container ref={inputFormContainer} className="py-4 px-5 form-container">
                    {
                        isLoading ?
                            <Row>
                                <Col>
                                    <div className="text-center pt-5">
                                        <Spinner className="mx-auto" />
                                        <div className="mx-auto">Loading data...</div>
                                    </div>
                                </Col>
                            </Row>
                            : null
                    }
                    {
                        hasData && isLoading === false ?
                            <Row>
                                <Col xs={12} lg={12}>
                                    <Row>
                                        <Col xs={3}></Col>
                                        <Col xs={9}>
                                            <Row>
                                                <Col xs={3} className="dr-column-heading align-bottom">
                                                    <div>Species</div>
                                                </Col>
                                                <Col xs={9}>
                                                    <Row>
                                                        <Col xs={4} className="dr-column-heading align-bottom">
                                                            <Stack direction="horizontal">
                                                                <div style={{ display: "inline" }}>No Interest</div>
                                                                <FontAwesomeIcon className="info-icon" icon={faInfoCircle} title="No or minimal interest in feed throughout day"></FontAwesomeIcon>
                                                            </Stack>
                                                        </Col>
                                                        <Col xs={4} className="dr-column-heading align-bottom">
                                                            <Stack direction="horizontal" gap={1} className="align-bottom align-items-end">
                                                                <div>Climbing, Smothering</div>
                                                                <FontAwesomeIcon className="info-icon" icon={faInfoCircle} title="Climbing, smothering, lunging or pushing present"></FontAwesomeIcon>
                                                            </Stack>
                                                        </Col>
                                                        <Col xs={4} className="dr-column-heading align-bottom">
                                                            <Stack direction="horizontal">
                                                                <div>Interested</div>
                                                                <FontAwesomeIcon className="info-icon" icon={faInfoCircle} title="Interested (when provided for interbal feeding/throughout day for ad lib feeding) +/- minor jostling"></FontAwesomeIcon>
                                                            </Stack>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {
                                        deckRecords.map((deck, deckIndex) => {
                                            return (
                                                <Row key={`deck${deck.deckID}`} className={`pb-3 pt-3 gray-border-top`} >
                                                    <Col xs={3} className="dr-text">
                                                        {deck.name}
                                                    </Col>
                                                    <Col xs={9}>
                                                        {
                                                            deck.detailedDeckRecords.map((dr, drIndex) => {
                                                                return (
                                                                    <FeedingBehaviour key={`genDemeanour${dr.Species.id}`} deckRecord={dr} onUpdate={onUpdate}
                                                                        hasPadding={drIndex !== deck.detailedDeckRecords.length - 1} onTriggerValidation={triggerShowInvalid} />
                                                                )
                                                            })
                                                        }
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }
                                </Col>
                            </Row>
                            : null
                    }
                </Container>
                {
                    isLoading === false && hasData === true ?
                        <Row className="pt-4 mx-0" style={{ position: "relative", top: "-300px" }}>
                            {
                                showSaveSuccess === true ?
                                    <Col xs={12}>
                                        <Container style={{ width: "874px" }}>
                                            <Row>
                                                <Col xs={12}>
                                                    <Alert variant='success'>Deck record updated successfully.</Alert>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                    : null
                            }
                            {
                                hasSaveError === true ?
                                    <Col xs={12}>
                                        <Container>
                                            <Row>
                                                <Col>
                                                    <Alert variant='danger'>Unable to save. Please wait a few minutes an try again. If the problem persists contact administration.</Alert>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                    : null
                            }
                            <EditLogModal
                                title="Edit feeding behaviour data"
                                page="Feeding Behaviour"
                                userID={userCtx.user.id}
                                consignmentIDs={[state.consignmentDay.consignmentId]}
                                showModal={editModalShow}
                                reasonOptions={editPageReasons}
                                onClose={onEditModalClosed}
                                onSaveSuccess={onEditModalSaveSuccess}
                                onSaveFail={onEditModalSaveFailed}
                            />
                            <Col xs={12}>
                                <Stack direction="horizontal" gap={2} style={{ justifyContent: "center" }}>
                                    <ReadWriteButton writeonly={false} readonlytext="Back" variant="secondary" onClick={() => { goBack() }} size="lg" style={{ minWidth: "150px" }}>Cancel</ReadWriteButton>
                                    {isReportEditable(userCtx, state.allowEditing) &&
                                        <ReadWriteButton writeonly={true} variant="primary" onClick={() => { validateAndSaveShowEditModal() }} size="lg" style={{ minWidth: "150px" }}>Update</ReadWriteButton>
                                    }
                                </Stack>
                            </Col>
                        </Row>
                        : null
                }
                {
                    (hasLoadError === true || hasData === false) && isLoading === false ?
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <Alert variant='danger'>Unable to load deck record.</Alert>
                                </Col>
                                <Col xs={12}>
                                    <Button className="me-2 ms-auto" variant="primary" onClick={() => { goBack() }} size="lg" style={{ minWidth: "150px" }}>Back</Button>
                                </Col>
                            </Row>
                        </Container>

                        : null
                }
            </Container >
        </LoggedInContainer >
    )
};

export default Edit_Deck_Record_Feeding_Behaviour;