import React, { useState, useEffect, useContext } from "react";
import LoggedInContainer from "../../layout/LoggedInContainer";
import { Container, Row, Col, ButtonGroup, Button, Stack, Form, Modal, Alert, Dropdown  } from "react-bootstrap";
import REConsignmentDetails from "../../components/RegisteredEstablishments/REConsignmentDetails";
import { useNavigate, useParams,Link } from "react-router-dom";
import { RegisteredEstablishmentConsignment } from "../../classes/RE/RegisteredEstablishmentConsignment";
import { 
    getTagTypes,
    getCommodityTypes, 
    getREConsignmentByID, 
    getSpecies, 
    getSpeciesClasses, 
    setRejectionsForConsignmentID, 
    getMortalityIDsMatchingSearch, 
    getDetailedMortalityConditions, 
    getManagementPlans, 
    getExporter, 
    setMortalitiesForREConsignment,
    deleteREConsignment } from "../../api/apiAccess";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faXmark,faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { StringIsNullUndefinedOrEmpty } from "../../utils/StringUtils";
import { formatInTimeZone, isOver365Days, isWithinSpecifiedNumberOfDays} from "../../utils/common";
import DateTimePicker from "../../components/DateTimePicker";
import { MediaQueryContext, UserContext } from "../..";
import { UserType } from "../../api/enums/EnumUserType";
import { Exporter } from "../../classes/Exporter";
import MortalityDragNDropUploader from "../../components/RegisteredEstablishments/MortalityDragNDropUploader";
import Swal from "../../utils/Swal";
import { TagType } from "../../classes/TagType";
import ConsignmentEditHistory from "../../components/ConsignmentDashboard/ConsignmentEditHistory";

let mortalitySearchSequence = 0;

const REConsignmentDashboard = () => {
    const mediaQueryCtx = useContext(MediaQueryContext);
    const userCtx = useContext(UserContext);
    const navigate = useNavigate();
    const {consignmentID} = useParams()
    const [species, setSpecies] = useState([]);
    const [speciesClasses, setSpeciesClasses] = useState([]);
    const [speciesAndClassOptions, setSpeciesAndClassOptions] = useState([]);
    const [mortalityConditions, setMortalityConditions] = useState([]);
    const [commodityTypes, setCommodityTypes] = useState([]);
    const [newRejectionRows, setNewRejectionsRows] = useState([]);
    const [pastRejectionRows, setPastRejectionsRows] = useState([]);
    const [consignment, setConsignment] = useState({});
    const [selectedDataView, setSelectedDataView] = useState("mortalityData");
    const [pastRejections, setPastRejections] = useState([]); // These are rejections that were recorded previously
    const [totalRejections, setTotalRejections] = useState([]); // These are past rejections + valid entries from rejectionRows;
    const [mortalities, setMortalities] = useState([]);
    const [filteredMortalities, setFilteredMortalities] = useState([]);
    const [mortalitySpeciesOptions, setMortalitySpeciesOptions] = useState([]);
    const [mortalitySpeciesClassOptions, setMortalitySpeciesClassOptions] = useState([]);
    const [mortalityLevelOneConditionOptions, setMortalityLevelOneConditionOptions] = useState([]);
    const [hasSaveRejectionError, setHasSaveRejectionError] = useState(false);
    const [speciesFilter, setSpeciesFilter] = useState('empty');
    const [classFilter, setClassFilter] = useState('empty');
    const [conditionFilter, setConditionFilter] = useState('empty');
    const [isCustomDatesFilterSelected, setIsCustomDatesFilterSelected] = useState(false);
    const [selectedCustomStartDate, setSelectedCustomStartDate] = useState(new Date());
    const [selectedCustomEndDate, setSelectedCustomEndDate] = useState(new Date());
    const [isConsignmentSubmitted, setIsConsignmentSubmitted] = useState(false);
    const [managementPlans, setManagementPlans] = useState([]);
    const [consignmentExporter, setConsignmentExporter] = useState("");
    const [isDeletable, setIsDeletable] = useState(false);
    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
    const [showCannotDeleteMessageModal, setShowCannotDeleteMessageModal] = useState(false);
    const [isUploadTemplate,setIsUploadTemplate] = useState(false);
    const [registeredEstablishment, setRegisteredEstablishment] = useState(null);
    const [isEditable, setIsEditable] = useState(false);

    // Search
    const [searchTerm, setSearchTerm] = useState("");
    const [searchedMortalities, setSearchedMortalities] = useState([]);
    const [visibleMortalities, setVisibleMortalities] = useState([]);

    const [importedMortalityRecords, setImportedMortalityRecords] = useState(null);
    const [tagTypes, setTagTypes] = useState([]);

    useEffect(() => {

        const fetchTagTypes = async () => {
            let results = await getTagTypes();
            if (results?.status === 200) {
                let earTagDetails = results.data.map(et => new TagType(et));
                setTagTypes(earTagDetails);
            }
        }

        const fetchSpecies = async () => {
            let results = await getSpecies();
            if (results?.status === 200) {
                setSpecies(results.data);
            }
        }

        const fetchSpeciesClasses = async() => {
            let results = await getSpeciesClasses();
            if (results?.status === 200) {
                setSpeciesClasses(results.data);
            }
        }

        const fetchCommodityTypes = async () => {
            let results = await getCommodityTypes();
            if (results.status === 200) {
                setCommodityTypes(results.data);
            }
        }

        const fetchManagementPlans = async() => {
            let results = await getManagementPlans(true);
            if (results.status === 200) {
                setManagementPlans(results.data);
            }
        }

        const fetchMortalityConditions = async () => {
            let results = await getDetailedMortalityConditions();
            if (results.status === 200) {
                setMortalityConditions(results.data);
            }
        }
        
        const fetchREConsignment = async () => {
            let results = await getREConsignmentByID(consignmentID);
            if (results?.status === 200) {
                let consignmentResult = new RegisteredEstablishmentConsignment(results.data);
                setConsignment(consignmentResult);
                setRegisteredEstablishment(consignmentResult.RegisteredEstablishments[0]);

                if (userCtx.user?.userTypeID === UserType.INDUSTRY) {
                    if (consignmentResult.DateSubmitted === null) {
                        setIsEditable(true)
                    } else {
                        setIsEditable(isWithinSpecifiedNumberOfDays(new Date(consignmentResult.DateSubmitted), 42))
                    }
                }
            }
        }
        fetchTagTypes();
        fetchSpecies();
        fetchSpeciesClasses();
        fetchManagementPlans();
        fetchMortalityConditions();
        fetchCommodityTypes();
        fetchREConsignment();
        initializeRejectionRows();
    }, []);

    useEffect(() => {
        const fetchExporterForConsignment = async () => {
            if (consignment.ExporterID) {
                let result = await getExporter(consignment.ExporterID)
                if (result.status === 200) {
                    let exporterForConsignment = new Exporter(result.data);
                    setConsignmentExporter(exporterForConsignment);
                }

            }
        }
        fetchExporterForConsignment();
        setIsConsignmentSubmitted(consignment?.IsSubmitted === true);
        let canBeDeleted = consignment?.ConsignmentMortalities?.length === 0 && consignment.IsSubmitted !== true && userCtx.user?.userTypeID !== UserType.REGULATORY && userCtx.user?.userTypeID !== UserType.LIVE_CORP;
        setIsDeletable(canBeDeleted);
    }, [consignment])

    useEffect(() => {
        let visible = searchedMortalities.filter(sm => filteredMortalities.map(fm => fm.mortalityID).includes(sm.mortalityID));
        setVisibleMortalities(visible);
    }, [filteredMortalities, searchedMortalities])

    useEffect(() => {
        mortalitySearchSequence++;
        if (searchTerm.length > 2) {
            searchMortalities(searchTerm);
        } else {
            clearSearch();
        }
    }, [searchTerm])

    const clearSearch = () => {
        setSearchedMortalities(filteredMortalities);
    }

    const searchMortalities = async (aSearchTerm) => {
        let results = await getMortalityIDsMatchingSearch(aSearchTerm, userCtx.associatedExporter.exporterID, mortalitySearchSequence);
        if (results.status === 200) {
            if (results.data.searchSequence === mortalitySearchSequence) {
                let searchResults = filteredMortalities.filter(m => results.data.relevantMortalityIDs.includes(m.mortalityID));
                setSearchedMortalities(searchResults);
            }
        }
    }

    useEffect(() => {
        if (Object.keys(consignment).length === 0) return;
        if (species.length === 0) return;
        if (speciesClasses.length === 0) return;

        let tempSpeciesAndClasses = [];
        consignment.ConsignmentAnimals.forEach(a => {
            if (tempSpeciesAndClasses.find(sc => Number(sc.speciesID) === Number(a.SpeciesID) && Number(sc.classID) === Number(a.ClassID)) !== undefined) return;
            tempSpeciesAndClasses.push({speciesID: a.SpeciesID, speciesName: species.find(s => Number(s.id) === Number(a.SpeciesID))?.speciesName, classID: a.ClassID, className: speciesClasses.find(c => Number(c.id) === Number(a.ClassID))?.className});
        });

        setSpeciesAndClassOptions(tempSpeciesAndClasses);
    }, [consignment, species, speciesClasses]);

    useEffect(() => {
        if (Object.keys(consignment).length === 0) return;
        if (speciesAndClassOptions.length === 0) return;

        let tempPastRejections = consignment.ConsignmentRejections.map(cr => {
            return {
                rejectionID: cr.ID, 
                speciesID: cr.SpeciesID, 
                classID: cr.ClassID, 
                numberOfHead: cr.NumberOfStock, 
                speciesName: species.find(sp => Number(sp.id) === Number(cr.SpeciesID))?.speciesName, 
                className: speciesClasses.find(sc => Number(sc.id)===Number(cr.ClassID))?.className
            }
        });
        setPastRejections(tempPastRejections);

        let tempMortalities = consignment.ConsignmentMortalities.map(mort => {
            return {
                mortalityID: mort.ID, 
                mortalityDate: mort.MortalityDate, 
                speciesID: mort.SpeciesID,
                classID: mort.ClassID,
                speciesClass: `${species.find(sp => Number(sp.id) === Number(mort.SpeciesID)).speciesName} - ${speciesClasses.find(cl => Number(cl.id) === Number(mort.ClassID)).className}`, 
                commodityType: `${commodityTypes.find(c => Number(c.id) === Number(mort.CommodityTypeID))?.commodityName}`,
                tag: mort.TagNumber, 
                diagnosisLevel1ID: mortalityConditions.find(hc => Number(hc.levelThree.id) === Number(mort.ConditionLevelThreeID))?.levelOne.id,
                diagnosisLevel1: mortalityConditions.find(hc => Number(hc.levelThree.id) === Number(mort.ConditionLevelThreeID))?.levelOne.conditionName, 
                diagnosisLevel2: mortalityConditions.find(hc => Number(hc.levelThree.id) === Number(mort.ConditionLevelThreeID))?.levelTwo.conditionName, 
                diagnosisLevel3: mortalityConditions.find(hc => Number(hc.levelThree.id) === Number(mort.ConditionLevelThreeID))?.levelThree.conditionName
            };
        })

        setMortalities(tempMortalities);
        setFilteredMortalities(tempMortalities);
        setSearchedMortalities(tempMortalities);

        setMortalitySpeciesOptions([...new Set(tempMortalities.map(tm => species.find(sp => Number(sp.id) === Number(tm.speciesID))))]);
        setMortalitySpeciesClassOptions([...new Set(tempMortalities.map(tm => speciesClasses.find(sc => Number(sc.id) === Number(tm.classID))))]);

        if(mortalityConditions.length > 0) setMortalityLevelOneConditionOptions([...new Set(tempMortalities.map(tm => mortalityConditions.find(hc => Number(hc.levelOne.id) === Number(tm.diagnosisLevel1ID)).levelOne))]);

    }, [consignment, speciesAndClassOptions, mortalityConditions, commodityTypes])

    useEffect(() => {
        initializeCustomDates();
        if (mortalities?.length > 0) {
            setIsDeletable(false);
        }
    }, [mortalities]);

    useEffect(() => {
        if (isConsignmentSubmitted) {
            setIsDeletable(false);
        }
    }, [isConsignmentSubmitted])

    const initializeCustomDates = () => {
        let tempMortalities = [...mortalities];

        if (tempMortalities?.length > 0) {
            let earliestMortalityDate = tempMortalities.length > 1 ? tempMortalities.map(tm => new Date(tm.mortalityDate)).reduce((a,b) => b < a ? b : a, new Date()) : new Date(tempMortalities[0].mortalityDate);
            earliestMortalityDate.setHours(0, 0, 0);
            setSelectedCustomStartDate(earliestMortalityDate);

            let latestMortalityDate = tempMortalities.length > 1 ? tempMortalities.map(tm => new Date(tm.mortalityDate)).reduce((a,b) => b > a ? b : a, new Date()) : new Date();
            latestMortalityDate.setHours(23, 59, 59);
            setSelectedCustomEndDate(latestMortalityDate);
        }
    }

    useEffect(() => {
        initializeRejectionRows();
    }, [pastRejections])

    

    useEffect(() => {
        let tempTotalRejections = [];
        let tempPastRejections = pastRejectionRows.filter(row => !isNaN(row.numberOfHead) && row.selectedSpeciesClass !== "" && row.selectedValue !== "empty").map(rr => {
            if (!isNaN(rr.numberOfHead) && rr.selectedSpeciesClass !== "" && rr.selectedSpeciesClass !== "empty") {
                return {speciesID: rr.selectedSpeciesClass.speciesID, classID: rr.selectedSpeciesClass.classID, numberOfHead: rr.numberOfHead, speciesName: species.find(sp => Number(sp.id) === Number(rr.selectedSpeciesClass.speciesID))?.speciesName, className: speciesClasses.find(sc => Number(sc.id)===Number(rr.selectedSpeciesClass.classID))?.className};
            }
        });
        let tempCurrentRejections = newRejectionRows.filter(row => !isNaN(row.numberOfHead) && row.selectedSpeciesClass !== "" && row.selectedValue !== "empty").map(rr => {
            if (!isNaN(rr.numberOfHead) && rr.selectedSpeciesClass !== "" && rr.selectedSpeciesClass !== "empty") {
                return {speciesID: rr.selectedSpeciesClass.speciesID, classID: rr.selectedSpeciesClass.classID, numberOfHead: rr.numberOfHead, speciesName: species.find(sp => Number(sp.id) === Number(rr.selectedSpeciesClass.speciesID))?.speciesName, className: speciesClasses.find(sc => Number(sc.id)===Number(rr.selectedSpeciesClass.classID))?.className};
            }
        });
        tempPastRejections.forEach(tpr => tempTotalRejections.push(tpr));
        tempCurrentRejections.forEach(tcr => tempTotalRejections.push(tcr));
        let tempTalliedTotalRejections = [];
        tempTotalRejections.forEach(ttr => {
            if (ttr === undefined) return;
            let matchingSpeciesClassIndex = tempTalliedTotalRejections.indexOf(tempTalliedTotalRejections.find(tallied => tallied.speciesID === ttr.speciesID));
            if (matchingSpeciesClassIndex === -1) {
                let tallyObject = ttr;
                tallyObject.totalNumberOfHead = tallyObject.numberOfHead;
                delete tallyObject.numberOfHead;
                tempTalliedTotalRejections.push(tallyObject);
            } else {
                tempTalliedTotalRejections[matchingSpeciesClassIndex].totalNumberOfHead = Number(tempTalliedTotalRejections[matchingSpeciesClassIndex].totalNumberOfHead) + Number(ttr.numberOfHead);
            }
        })
        setTotalRejections(tempTalliedTotalRejections.filter(tttr => Number(tttr.totalNumberOfHead) > 0));
    }, [newRejectionRows, pastRejectionRows]);

    useEffect(() => {
        let tempMortalities = [...mortalities];
        let tempFilteredMortalities = tempMortalities.filter(m => { 
            let matchesSpeciesFilter = (speciesFilter === 'empty' || Number(m.speciesID) === Number(speciesFilter));
            let matchesClassFilter = (classFilter === 'empty' || Number(m.classID) === Number(classFilter));
            let matchesConditionFilter = (conditionFilter === 'empty' || Number(m.diagnosisLevel1ID) === Number(conditionFilter));
            let fromDate = new Date(selectedCustomStartDate);
            let toDate = new Date(selectedCustomEndDate);
            let mortDate = new Date(m.mortalityDate);
            let comparisonDate = new Date(mortDate.getFullYear(), mortDate.getMonth(), mortDate.getDate(), 0, 0, 0);
            let mortalityIsAfterFilterOrEqual = comparisonDate >= fromDate;
            let mortalityIsBeforeFilterOrEqual = comparisonDate <= toDate;
            let matchesDateFilter = mortalityIsAfterFilterOrEqual && mortalityIsBeforeFilterOrEqual;
            return matchesSpeciesFilter && matchesClassFilter && matchesConditionFilter && matchesDateFilter;
        });
        setFilteredMortalities(tempFilteredMortalities);
    }, [speciesFilter, classFilter, conditionFilter, selectedCustomStartDate, selectedCustomEndDate])

    const initializeRejectionRows = () => {
        let tempPastRejectionRows = [];
        pastRejections.forEach((pr, prIndex) => {
            tempPastRejectionRows.push({rowID: prIndex, numberOfHead: pr.numberOfHead.toString(), selectedSpeciesClass: pr, selectedValue: speciesAndClassOptions.indexOf(speciesAndClassOptions.find(sco => Number(sco.speciesID) === Number(pr.speciesID) && Number(sco.classID) === Number(pr.classID))), existingRejectionID: pr.rejectionID});
        })
        setPastRejectionsRows(tempPastRejectionRows);

        let tempNewRejectionRows = [];
        tempNewRejectionRows = [];
        while (tempNewRejectionRows.length < 5 - tempPastRejectionRows.length) {
            let rowIDIndex = tempNewRejectionRows.length + tempPastRejectionRows.length;
            tempNewRejectionRows.push({rowID: rowIDIndex, numberOfHead: "", selectedSpeciesClass: "", selectedValue: "empty", existingRejectionID: null});
            rowIDIndex++;
        }
        setNewRejectionsRows(tempNewRejectionRows);
    }

    const updateNewRejectionsRowSelectedSpeciesClass = (row, selectedSpeciesClass, selectedIndex) => {
        let tempRejectionsRows = [...newRejectionRows];
        let indexToUpdate = tempRejectionsRows.indexOf(tempRejectionsRows.find(rr => rr.rowID === row.rowID));        
        tempRejectionsRows[indexToUpdate].selectedSpeciesClass = selectedSpeciesClass && selectedIndex !== "empty" ? {speciesID: selectedSpeciesClass.speciesID, classID: selectedSpeciesClass.classID} : ""; 
        tempRejectionsRows[indexToUpdate].numberOfHead = row.numberOfHead;
        tempRejectionsRows[indexToUpdate].selectedValue = selectedIndex;
        setNewRejectionsRows(tempRejectionsRows);
    }

    const updateNewRejectionsRowNumberOfHead = (row, numberOfHead) => {
        setHasSaveRejectionError(false);
        let tempRejectionsRows = [...newRejectionRows];
        let indexToUpdate = tempRejectionsRows.indexOf(tempRejectionsRows.find(rr => rr.rowID === row.rowID));
        tempRejectionsRows[indexToUpdate].numberOfHead = numberOfHead;
        setNewRejectionsRows(tempRejectionsRows);
    }

    const updatePastRejectionsRowSelectedSpeciesClass = (row, selectedSpeciesClass, selectedIndex) => {
        let tempRejectionsRows = [...pastRejectionRows];
        let indexToUpdate = tempRejectionsRows.indexOf(tempRejectionsRows.find(rr => rr.rowID === row.rowID));        
        tempRejectionsRows[indexToUpdate].selectedSpeciesClass = selectedSpeciesClass && selectedIndex !== "empty" ? {speciesID: selectedSpeciesClass.speciesID, classID: selectedSpeciesClass.classID} : ""; 
        tempRejectionsRows[indexToUpdate].numberOfHead = row.numberOfHead;
        tempRejectionsRows[indexToUpdate].selectedValue = selectedIndex;
        setPastRejectionsRows(tempRejectionsRows);
    }

    const updatePastRejectionsRowNumberOfHead = (row, numberOfHead) => {
        setHasSaveRejectionError(false);
        let tempRejectionsRows = [...pastRejectionRows];
        let indexToUpdate = tempRejectionsRows.indexOf(tempRejectionsRows.find(rr => rr.rowID === row.rowID));
        tempRejectionsRows[indexToUpdate].numberOfHead = numberOfHead;
        setPastRejectionsRows(tempRejectionsRows);
    }

    const addRejectionRow = () => {
        setHasSaveRejectionError(false);
        let tempRejectionRows = [...newRejectionRows];
        let lastRowID = tempRejectionRows.sort((a,b) => Number(a.rowID) > Number(b.rowID) ? 1 : -1)[tempRejectionRows.length - 1].rowID;
        tempRejectionRows.push({rowID: Number(lastRowID) + 1, numberOfHead: "", selectedSpeciesClass: "", selectedValue: "empty"})
        setNewRejectionsRows(tempRejectionRows);
    }

    const removeNewRejectionRow = (rowID) => {
        setHasSaveRejectionError(false);
        let tempRejectionRows = [...newRejectionRows];
        let indexToRemove = tempRejectionRows.indexOf(tempRejectionRows.find(r => r.rowID === rowID));
        tempRejectionRows.splice(indexToRemove, 1);
        
        if (tempRejectionRows.length < 1) {   
            tempRejectionRows.push({rowID: 0, selectedSpeciesClass: "", selectedValue: "empty"})
        }
        setNewRejectionsRows(tempRejectionRows);
    }

    const removePastRejectionRow = (rowID) => {
        setHasSaveRejectionError(false);
        let tempRejectionRows = [...pastRejectionRows];
        let indexToRemove = tempRejectionRows.indexOf(tempRejectionRows.find(r => r.rowID === rowID));
        tempRejectionRows.splice(indexToRemove, 1);
        
        if (tempRejectionRows.length < 1) {   
            tempRejectionRows.push({rowID: 0, selectedSpeciesClass: "", selectedValue: "empty"})
        }
        setPastRejectionsRows(tempRejectionRows);
    }

    const saveRejections = async () => {
        setHasSaveRejectionError(false);
        let pastRejections = [...pastRejectionRows];
        let newRejections = [...newRejectionRows];
        newRejections = newRejections.filter(r => !StringIsNullUndefinedOrEmpty(r.numberOfHead) && !isNaN(r.numberOfHead) && SelectedSpeciesClassObjectIsValid(r.selectedSpeciesClass) && r.selectedValue !== 'empty');
        let parsedPastRejections = pastRejections.map(r => { return { RegisteredEstablishmentConsignmentID: consignmentID, RegisteredestablishmentConsignmentRejectionID: r.existingRejectionID, SpeciesID: r.selectedSpeciesClass.speciesID, ClassID: r.selectedSpeciesClass.classID, numberOfStock: r.numberOfHead}})
        let parsedNewRejections = newRejections.map(r => { return { RegisteredEstablishmentConsignmentID: consignmentID, RegisteredestablishmentConsignmentRejectionID: null, SpeciesID: r.selectedSpeciesClass.speciesID, ClassID: r.selectedSpeciesClass.classID, numberOfStock: r.numberOfHead}})
        let combinedRejections = parsedPastRejections.concat(parsedNewRejections);
        let results = await setRejectionsForConsignmentID(combinedRejections);
        if (results.status === 200) {
            let consignmentResult = new RegisteredEstablishmentConsignment(results.data);
            setConsignment(consignmentResult);
        } else {
            setHasSaveRejectionError(true);
        }
    }

    const SelectedSpeciesClassObjectIsValid = (selectedSpeciesClassObject) => {
        if (selectedSpeciesClassObject instanceof String) return false;
        if (Object.keys(selectedSpeciesClassObject).find(k => k === "speciesID") === undefined) return false;
        if (Object.keys(selectedSpeciesClassObject).find(k => k === "classID") === undefined) return false;
        if (Object.keys(selectedSpeciesClassObject).length !== 2) return false;
        if (Object.values(selectedSpeciesClassObject).find(v => isNaN(v) || Number(v) <= 0) !== undefined) return false;
        return true;
    }
    const goToBack = () => {
        if (userCtx.user?.userTypeID === UserType.INDUSTRY || userCtx.user?.userTypeID === UserType.LIVE_CORP) {
            navigate("/re/dashboard");
        } else {
            navigate("/re/historicalconsignments");
        }
    }

    const consignmentSubmitted = (isSubmitted) => {
        setIsConsignmentSubmitted(isSubmitted)
    }

    const onDeleteConsignmentClicked = () => {
        setShowDeleteConfirmationDialog(true);
    }

    const onUndeletableDeleteConsignmentClicked = () => {
        setShowCannotDeleteMessageModal(true);
    }

    const onCloseCannotDeleteModal = () => {
        setShowCannotDeleteMessageModal(false);
    }

    const onConfirmDeleteConsignmentClicked = async () => {
        const deleteConsignment = async () => {
            let result = await deleteREConsignment(consignment.ID);
            if (result.status === 200) {
                return result.data === true;
            } else {
                return false;
            }
        }

        if (await deleteConsignment()) {
            navigate("/re/dashboard");
        }
    }

    const onCancelDeleteConsignmentClicked = () => {
        setShowDeleteConfirmationDialog(false);
    }

    const onCancelAddMortalitiesModal = () => {
        setIsUploadTemplate(false);
        setImportedMortalityRecords(null);
    }

    const onConfirmAddMortalities = async ()=>{
        setIsUploadTemplate(false);

        console.log("saving imported mortality records", importedMortalityRecords);

        if (importedMortalityRecords !== null) {
            let results = await setMortalitiesForREConsignment(importedMortalityRecords, consignmentID);
            if (results.status === 200) {
                Swal.Toast("Imported mortality records successfully");

                navigate(`/re/consignmentdashboard/${consignmentID}`);                
            }else{
                Swal.Toast("Imported mortality records failed", "error");
            }
        }
    }

    const dragAndDropDataImported = (data) => {
        console.log("dragAndDropDataImported",data);
        setImportedMortalityRecords(data);
    }

    const importedFileReset = ()=>{
        setImportedMortalityRecords(null);
    }

    return (
        <LoggedInContainer>
            <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
                <Row className="upper-panel mx-0">
                    <Col>
                        <Container>
                            <Row>
                                <Col xs={2} className='my-auto'>
                                    <Button variant="back" onClick={goToBack} >
                                        <FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: "5px" }} />
                                        Back
                                    </Button>

                                </Col>
                                <Col xs={8}>
                                    <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", lineHeight: "73px", fontSize: "35px" }}>RE Consignment dashboard</div>
                                    <div className="text-center subtitle-text-heavy mb-2 mt-3" style={{ color: "#FFF", fontSize: "28px" }}>
                                        <Stack direction="horizontal" gap={5} className="justify-content-center">
                                            <div>LNC-{consignment?.LNCNumber}</div>
                                            { registeredEstablishment &&
                                                <div>{registeredEstablishment?.LAENumber}{registeredEstablishment?.LAENumber && "-"}{registeredEstablishment?.LAEName}</div>
                                            }
                                        </Stack>
                                        </div>
                                </Col>

                                <Col xs={2} className='my-auto'>
                                    {isEditable &&
                                        <Button className="mt-2 w-100" variant="back" onClick={() => navigate(`/re/editconsignment/${consignmentID}`)}>
                                            Edit
                                        </Button>
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row>
                    <Container fluid className="py-4 px-5 form-container-mid" style={{top: "-300px", minHeight: "600px"}}>
                        <REConsignmentDetails consignment={consignment} consignmentExporter={consignmentExporter} species={species} speciesClasses={speciesClasses} managementPlans={managementPlans} onConsignmentSubmitted={consignmentSubmitted}/>
                        {
                            isDeletable ? (
                                <Row className="mt-4">
                                    <Col xs={12}>
                                        <Stack className="pe-1">
                                            <Button 
                                                className="ms-auto me-2" 
                                                size="sm" 
                                                variant="secondary"
                                                onClick={onDeleteConsignmentClicked}
                                            >
                                                    Delete Consignment
                                            </Button>
                                        </Stack>
                                    </Col>
                                </Row>
                            ) : null
                        }

{
                            !isDeletable && consignment?.ConsignmentMortalities?.length > 0 && userCtx.user?.userTypeID  !== UserType.REGULATORY && userCtx.user?.userTypeID  !== UserType.LIVE_CORP ? (
                                <Row className="mt-4">
                                    <Col xs={12}>
                                        <Stack className="pe-1">
                                            <Button 
                                                className="ms-auto me-2" 
                                                size="sm" 
                                                variant="secondary"
                                                onClick={onUndeletableDeleteConsignmentClicked}
                                            >
                                                    Delete Consignment
                                            </Button>
                                        </Stack>
                                    </Col>
                                </Row>
                            ) : null
                        }
                        <Row className={isDeletable || !isDeletable && consignment?.ConsignmentMortalities?.length > 0 ? "mt-0" : "mt-4"}>
                            <Col xs={3}>
                                { /* 
                                    TEMPORARY - HIDE THE TOGGLE FOR REJECTIONS/MORTALITIES
                                    THIS WILL BE REINTRODUCED LATER SO DON'T DELETE --- 25/09/2023 (Mark) SEE LX-806
                                    
                                    <ButtonGroup>
                                        <Button 
                                            variant={selectedDataView === "mortalityData" ? "primary" : "secondary"}
                                            onClick={(e) => {
                                                setSelectedDataView("mortalityData");
                                            }}
                                        >
                                            Mortalities
                                        </Button>
                                            
                                        <Button 
                                            variant={selectedDataView === "rejectionData" ? "primary" : "secondary"}
                                            onClick={(e) => {
                                                setSelectedDataView("rejectionData");
                                            }}
                                        >
                                            Rejections
                                        </Button>
                                    </ButtonGroup>
                                    */
                                }
                            </Col>
                        </Row>
                        { 
                            selectedDataView === "rejectionData" ? (
                                <Container className="mt-3 mx-0 px-0">
                                    <Row>
                                        <Col xs={9}>
                                            <Row className="mb-2">
                                                <Col xs={3}>
                                                    No. Of Head
                                                </Col>
                                                <Col xs={9}>
                                                    Species/Class
                                                </Col>
                                            </Row>
                                        {
                                            userCtx.user?.userTypeID !== UserType.INDUSTRY && pastRejectionRows.length === 0 ? (
                                                <div className="mt-4 text-center">
                                                    No rejections have been recorded for this consignment.
                                                </div>
                                            ) : null
                                        }

                                        {
                                            pastRejectionRows.map((rr, i) => {
                                                return (
                                                    <Row key={`past_rejection_row_${rr.rowID}`} className="mb-2">
                                                        <Col xs={3}>
                                                            <Form.Control
                                                                onChange={(e) => updatePastRejectionsRowNumberOfHead(rr, e.target.value)}
                                                                value={rr.numberOfHead}
                                                                disabled={userCtx.user?.userTypeID !== UserType.INDUSTRY}
                                                            >

                                                            </Form.Control>
                                                        </Col>
                                                        <Col xs={9}>
                                                            <Stack direction="horizontal">
                                                                <Form.Select
                                                                    onChange={(e) => {
                                                                        let selection = speciesAndClassOptions[e.target.selectedIndex - 1]
                                                                        updatePastRejectionsRowSelectedSpeciesClass(rr, selection, e.target.value)}
                                                                    }
                                                                    disabled={userCtx.user?.userTypeID !== UserType.INDUSTRY}
                                                                    value={rr.selectedValue}
                                                                    className={`${userCtx.user?.userTypeID !== UserType.INDUSTRY ? "dropdown-toggle-hide" : ""}`}
                                                                >
                                                                    <option value="empty">Select species and class</option>
                                                                    {
                                                                        
                                                                        speciesAndClassOptions.map((sc, scIndex) => {
                                                                            return (
                                                                                <option key={`species_class_option_${scIndex}`} value={scIndex}>{`${sc.speciesName} - ${sc.className}`}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Select>
                                                                {
                                                                    userCtx.user?.userTypeID === UserType.INDUSTRY ? 
                                                                    (
                                                                        <div 
                                                                            onClick={() => removePastRejectionRow(rr.rowID)}
                                                                            className="text-end clickable hoverable ms-2" 
                                                                            style={{width: "18px", borderRadius: "18px", height: "18px"}}
                                                                        >
                                                                            <FontAwesomeIcon icon={faXmark}/>
                                                                        </div>
                                                                    ) : null
                                                                }
                                                            </Stack>
                                                        </Col>

                                                    </Row>
                                                )
                                            })
                                            
                                        }
                                        {
                                            newRejectionRows.map((rr, i) => {
                                                if (userCtx.user?.userTypeID !== UserType.INDUSTRY) return;
                                                return (
                                                    <Row key={`rejection_row_${rr.rowID}`} className="mb-2">
                                                        <Col xs={3}>
                                                            <Form.Control
                                                                onChange={(e) => updateNewRejectionsRowNumberOfHead(rr, e.target.value)}
                                                                value={rr.numberOfHead}
                                                            >

                                                            </Form.Control>
                                                        </Col>
                                                        <Col xs={9}>
                                                            <Stack direction="horizontal">
                                                                <Form.Select
                                                                    onChange={(e) => {
                                                                        let selection = speciesAndClassOptions[e.target.selectedIndex - 1]
                                                                        updateNewRejectionsRowSelectedSpeciesClass(rr, selection, e.target.value)}
                                                                    }
                                                                    value={rr.selectedValue}
                                                                >
                                                                    <option value="empty">Select species and class</option>
                                                                    {
                                                                        
                                                                        speciesAndClassOptions.map((sc, scIndex) => {
                                                                            return (
                                                                                <option key={`species_class_option_${scIndex}`} value={scIndex}>{`${sc.speciesName} - ${sc.className}`}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Select>
                                                                <div 
                                                                    onClick={() => removeNewRejectionRow(rr.rowID)}
                                                                    className="text-end clickable hoverable ms-2" 
                                                                    style={{width: "18px", borderRadius: "18px", height: "18px"}}
                                                                >
                                                                    <FontAwesomeIcon icon={faXmark}/>
                                                                </div>
                                                            </Stack>
                                                        </Col>

                                                    </Row>
                                                )
                                            })
                                            
                                        }
                                            <Row>
                                                {
                                                    userCtx.user?.userTypeID === UserType.INDUSTRY ? 
                                                    (
                                                        <Col xs={12}>
                                                            <div className="cc-add-port-button px-2 py-1 mb-4" onClick={addRejectionRow}><FontAwesomeIcon className="primary-green" icon={faCirclePlus} /> Add another row</div>
                                                        </Col>        
                                                    ) : null
                                                }
                                            </Row>
                                        </Col>
                                        <Col xs={3} className="mt-4">
                                            <Row className="mx-0">
                                                <Col style={{backgroundColor: "rgba(245, 245, 245, 0.85)", minHeight: "200px", borderRadius: "3px", paddingTop: "8px"}}>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <div className="heading-text darkblue">Totals</div>
                                                            <div className="bg-override-primary mt-1 mb-3" style={{height: "2px"}}></div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        {
                                                            totalRejections.length === 0 ? (
                                                                <div className="text-center">No rejections have been recorded for this consignment.</div>
                                                            ) : null
                                                        } 
                                                        {
                                                            totalRejections.sort((a,b) => (a.speciesName > b.speciesName) ? 1 : -1).map((r, i) => {
                                                                return (
                                                                    <Row key={`rejection_${i}`}>
                                                                        <Col xs={9}>
                                                                            {r?.speciesName}
                                                                        </Col>
                                                                        <Col xs={3} className="text-end">
                                                                            {r?.totalNumberOfHead}
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            })
                                                        }
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {
                                        hasSaveRejectionError ? (
                                            <Row>
                                                <Alert variant="danger">An error occurred while saving rejections.  Please try again later.</Alert>
                                            </Row>
                                        ) : null
                                    }
                                    {
                                        !consignment?.IsSubmitted && userCtx.user?.userTypeID === UserType.INDUSTRY ? (
                                            <Row className="mt-4">
                                                <Col xs={3}>
                                                    <Button onClick={saveRejections}>
                                                        Save Rejections
                                                    </Button>
                                                </Col>
                                            </Row>
                                        ) : null
                                    }
                                </Container>
                            ):null
                        }

                        { 
                            selectedDataView === "mortalityData" ? (
                                <Container className="mt-3">
                                    <Row>
                                        <Col xs={12} className="text-end pt-1">
                                            { userCtx.user?.userTypeID === UserType.INDUSTRY && !isConsignmentSubmitted ? 
                                                (
                                                    <Dropdown as={ButtonGroup} >
                                                        <Button
                                                            className="btn btn-sm"
                                                            onClick={() => navigate(`/re/addmortality/${consignmentID}`)}
                                                        >
                                                            Add mortality
                                                        </Button>

                                                        <Dropdown.Toggle split  />

                                                        <Dropdown.Menu align="end">
                                                            <Dropdown.Item onClick={()=>setIsUploadTemplate(true)}>Add Mortalities via template</Dropdown.Item>
                                                            <Dropdown.Item href="/files/RE_mortality_import_template_V1.0.xlsx">Download Template</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                            
                                                ): null
                                            }

                                               
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={2}>
                                            <Stack>
                                                <div>Search</div>
                                                <div>
                                                    <Form.Control
                                                        placeholder="Type a word to search..."
                                                        onChange={(e) => {
                                                            let text = e.target.value;
                                                            setSearchTerm(text);
                                                        }}
                                                    >

                                                    </Form.Control>
                                                </div>
                                            </Stack>
                                        </Col>
                                        <Col xs={10}>
                                            <Stack>
                                                <div>Filter mortalities by</div>

                                                    <Container className="mx-0 px-0">
                                                        <Row>
                                                            <Col xs={4}>
                                                                <Stack direction="vertical">
                                                                    <Form.Select
                                                                        onChange={(e) => {
                                                                            if (e.target.value === "custom") {
                                                                                setIsCustomDatesFilterSelected(true);
                                                                            } else {
                                                                                setIsCustomDatesFilterSelected(false);
                                                                            }

                                                                            if (e.target.value === "all") {
                                                                                initializeCustomDates();
                                                                            }

                                                                            if (e.target.value === "today") {
                                                                                let today = new Date();
                                                                                let startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
                                                                                let endDate = new Date(today.getFullYear(), today.getMonth(), startDate.getDate() + 1, 0, 0, 0);
                                                                                setSelectedCustomStartDate(startDate);
                                                                                setSelectedCustomEndDate(endDate);
                                                                            }

                                                                            if (e.target.value === "this week") {
                                                                                let today = new Date();
                                                                                let altToday = new Date(today);
                                                                                let startOfWeek = new Date(altToday.setDate(today.getDate() - today.getDay()));
                                                                                let startDate = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate(), 0,0,0);
                                                                                let endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 0,0,0);
                                                                                setSelectedCustomStartDate(startDate);
                                                                                setSelectedCustomEndDate(endDate);
                                                                            }

                                                                            if (e.target.value === "last week") {
                                                                                let today = new Date();
                                                                                let altToday = new Date(today);
                                                                                let startOfWeek = new Date(altToday.setDate(today.getDate() - today.getDay()));
                                                                                let startDate = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() - 7, 0,0,0);
                                                                                let endDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 7, 0,0,0);
                                                                                setSelectedCustomStartDate(startDate);
                                                                                setSelectedCustomEndDate(endDate);
                                                                            }
                                                                        }}
                                                                    >
                                                                        <option value="all">All Dates</option>
                                                                        <option value="today">Today</option>
                                                                        <option value="this week">This week</option>
                                                                        <option value="last week">Last week</option>
                                                                        <option value="custom">Custom dates</option>                                                                    
                                                                    </Form.Select>
                                                                    {
                                                                        isCustomDatesFilterSelected ? (
                                                                            <Stack direction="horizontal">
                                                                                <DateTimePicker
                                                                                    datetime={selectedCustomStartDate}
                                                                                    showUTCTime={false}
                                                                                    onChange={(date) => {
                                                                                        setSelectedCustomStartDate(date);
                                                                                    }}
                                                                                    showTime={false}
                                                                                    canwrite={true}
                                                                                />
                                                                                <div className="px-1">to</div>
                                                                                <DateTimePicker
                                                                                    datetime={selectedCustomEndDate}
                                                                                    showUTCTime={false}
                                                                                    onChange={(date) => {
                                                                                        setSelectedCustomEndDate(date);
                                                                                    }}
                                                                                    showTime={false}
                                                                                    canwrite={true}
                                                                                />
                                                                            </Stack>
                                                                        ) : null
                                                                    }
                                                                </Stack>
                                                            </Col>
                                                            <Col xs={2}>
                                                                <Form.Select
                                                                    onChange={(e) => {
                                                                        setSpeciesFilter(e.target.value);
                                                                    }}
                                                                >
                                                                    <option value="empty">All Species</option>
                                                                    {
                                                                        mortalitySpeciesOptions.map((opt, i) => {
                                                                            return (
                                                                                <option key={`mortality_species_opt_${i}`} value={opt.id}>{opt.speciesName}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Select>
                                                            </Col>
                                                            <Col xs={2}>
                                                                <Form.Select
                                                                onChange={(e) => {
                                                                    setClassFilter(e.target.value);
                                                                }}
                                                                >
                                                                    <option value="empty">All Classes</option>
                                                                    {
                                                                       mortalitySpeciesClassOptions.map((opt, i) => {
                                                                        return (
                                                                            <option key={`mortality_class_opt_${i}`} value={opt.id}>{opt.className}</option>
                                                                        )
                                                                    })
                                                                    }
                                                                </Form.Select>
                                                            </Col>
                                                            <Col xs={4}>
                                                                <Form.Select
                                                                    onChange={(e) => {
                                                                        setConditionFilter(e.target.value);
                                                                    }}
                                                                >
                                                                    <option value="empty">All L1 Conditions</option>
                                                                    {
                                                                        mortalityLevelOneConditionOptions.map((opt, i) => {
                                                                            return (
                                                                                <option key={`mortality_condition_opt_${i}`} value={opt.id}>{opt.conditionName}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Form.Select>
                                                            </Col>
     
                                                        </Row>
                                                    </Container>

                                            </Stack>
                                        </Col>
                                    </Row>
                                    { 
                                        mortalities.length === 0 ? (
                                            <Row className="mt-5">
                                                <Col xs={12} className="text-center">
                                                    {mortalities.length === 0 ? "No mortalities have been recorded for this consignment." : "No mortalities have been recorded for this consignment which match the selected filter."}
                                                </Col>
                                            </Row>
                                        ) :null
                                    }
                                    {
                                        mortalities.length > 0 ? (
                                            <>
                                                <Row className="mt-3">
                                                    <Col xs={2} className="field-label">Mortality Date</Col>
                                                    <Col xs={2} className="field-label">Animal</Col>
                                                    <Col xs={2} className="field-label">Tag</Col>
                                                    <Col xs={5} className="field-label">Diagnosis/Presumptive Diagnosis</Col>
                                                    <Col xs={1} className="field-label">Action</Col>
                                                </Row>
                                                {
                                                    visibleMortalities.map((m, i) => {
                                                        let bgColor = i % 2 ? "#FFFFFF" : "#E3E3E3";
                                                        return (
                                                            <Row key={`filtered_mortality_${m.id}_${i}`} className="py-2" style={{backgroundColor: bgColor, borderBottom: "1px solid #44cb6f"}}>
                                                                <Col xs={2}>
                                                                    <Stack direction="vertical">
                                                                        <div>{formatInTimeZone(m.mortalityDate, "dd/MM/yyyy", Intl.DateTimeFormat().resolvedOptions().timeZone)}</div>
                                                                    </Stack>
                                                                </Col>
                                                                <Col xs={2}>
                                                                    <Stack direction="vertical">
                                                                        <div>{m.speciesClass}</div>
                                                                        <div>{m.commodityType}</div>
                                                                    </Stack>
                                                                </Col>
                                                                <Col xs={2}>
                                                                    {m.tag}
                                                                </Col>
                                                                <Col xs={5}>
                                                                    <Stack direction="vertical">
                                                                        <div>{m.diagnosisLevel1}</div>
                                                                        <div className="ms-2">{m.diagnosisLevel2}</div>
                                                                        <div className="ms-4">{m.diagnosisLevel3}</div>
                                                                    </Stack>
                                                                </Col>
                                                                <Col xs={1} style={{display: "flex", alignItems: "center"}}>
                                                                    <Stack direction="vertical" gap={2}>
                                                                        <Button
                                                                            onClick={() => {
                                                                                navigate(`/re/mortality/${m.mortalityID}`);
                                                                            }}
                                                                        >View</Button>
                                                                        { !isConsignmentSubmitted && userCtx.user?.writeAccess === true ?
                                                                            (
                                                                                <Button
                                                                                    onClick={() => navigate(`/re/addmortality/${consignmentID}`, {state: {mortalityID: m.mortalityID}})}
                                                                                >Edit</Button>
                                                                            ) : null
                                                                        }
                                                                    </Stack>

                                                                </Col>
                                                            </Row>
                                                        )
                                                    })
                                                }
                                            </>
                                        ): null
                                    }

                                    <Row className="pt-5">
                                        <Col xs={mediaQueryCtx.mobile === true ? 12 : 8}>
                                            <div className="heading-text darkblue" style={{ fontSize: "16px" }}>
                                                Consignment edit history
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="mx-0">
                                        <ConsignmentEditHistory consignmentID={consignmentID}
                                        isMobile={mediaQueryCtx.mobile === true}
                                        isREConsignment={true} />
                                    </Row>

                                </Container>
                            ):null
                        }
                    </Container>
                </Row>

            </Container>

            <Modal show={showDeleteConfirmationDialog} onHide={onCancelDeleteConsignmentClicked}>
                <Modal.Header closeButton>
                <Modal.Title>Delete Consignment</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure that you want to delete the consignment? This action can not be undone.</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={onCancelDeleteConsignmentClicked}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={onConfirmDeleteConsignmentClicked}>
                    Confirm
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showCannotDeleteMessageModal} onHide={onCloseCannotDeleteModal}>
                <Modal.Header closeButton>
                <Modal.Title>Delete Consignment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <em>You cannot delete a consignment that has a mortality.</em>
                    <br/>
                    <em>Contact LiveCorp for assistance.</em>
                </Modal.Body>
                <Modal.Footer>

                <Button variant="primary" onClick={onCloseCannotDeleteModal}>
                    OK
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={isUploadTemplate} onHide={onCancelAddMortalitiesModal} size="xl">
                <Modal.Header closeButton>
                <Modal.Title>Import mortalities from template file</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MortalityDragNDropUploader onImported={dragAndDropDataImported} onReset={importedFileReset}
                     species={species} 
                     classes={speciesClasses} 
                     managementPlans={managementPlans} 
                     mortalityConditions={mortalityConditions}
                     commodityTypes={commodityTypes}
                     tagTypes={tagTypes}
                     />
                </Modal.Body>
                <Modal.Footer>

                <Button variant="secondary" onClick={onCancelAddMortalitiesModal}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={onConfirmAddMortalities}>
                    Confirm
                </Button>
                </Modal.Footer>
            </Modal>


        </LoggedInContainer>
    )
}

export default REConsignmentDashboard;